import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const HareketTipiData = [
  { value: "hareketTipi1", label: "Hareket Tipi 1" },
  { value: "hareketTipi2", label: "Hareket Tipi 2" },
];
const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const IslemDurumuData = [
  { value: "islemDurumu1", label: "İşlem Durumu 1" },
  { value: "islemDurumu2", label: "İşlem Durumu 2" },
];
const CariTipiData = [
  { value: "cariTipi1", label: "Cari Tipi 1" },
  { value: "cariTipi2", label: "Cari Tipi 2" },
];
const IslemTipiData = [
  { value: "islemTipi1", label: "İşlem Tipi 1" },
  { value: "islemTipi2", label: "İşlem Tipi 2" },
];
const ParaBirimiData = [
  { value: "TRY", label: "TRY" },
  { value: "USD", label: "USD" },
];
const KategoriData = [
  { value: "kategori1", label: "Kategori 1" },
  { value: "kategori2", label: "Kategori 2" },
];
const KategoriDurumuData = [
  { value: "kategoriDurumu1", label: "Kategori Durumu 1" },
  { value: "kategoriDurumu2", label: "Kategori Durumu 2" }
];
const KategoriBazliHesapHareketleri = () => {
  const { t } = useTranslation();
  document.title = t("Kategori Bazlı Hesap Hareketleri");

  const [firma, setFirma] = useState(null);
  const [hareketTipi, setHareketTipi] = useState(null);
  const [islemKodu, setIslemKodu] = useState("");
  const [banka, setBanka] = useState(null);
  const [tutarMin, setTutarMin] = useState("");
  const [tutarMax, setTutarMax] = useState("");
  const [tutarMinRaw, setTutarMinRaw] = useState("");
  const [tutarMaxRaw, setTutarMaxRaw] = useState("");
  const [islemDurumu, setIslemDurumu] = useState(null);
  const [cariAdi, setCariAdi] = useState("");
  const [cariTipi, setCariTipi] = useState(null);
  const [aciklama, setAciklama] = useState("");
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");
  const [dekontNo, setDekontNo] = useState("");
  const [tcKimlikNoVergiNo, setTcKimlikNoVergiNo] = useState("");
  const [islemTipi, setIslemTipi] = useState(null);
  const [paraTransferKodu, setParaTransferKodu] = useState("");
  const [karsiIban, setKarsiIban] = useState("");
  const [paraBirimi, setParaBirimi] = useState(null);
  const [kategori, setKategori] = useState("");
  const [kategoriDurumu, setKategoriDurumu] = useState(null);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);
  const [hareketler, setHareketler] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function hareketTipiIslem(selectedSingle) {
    setHareketTipi(selectedSingle);
  }
  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function islemDurumuIslem(selectedSingle) {
    setIslemDurumu(selectedSingle);
  }
  function cariTipiIslem(selectedSingle) {
    setCariTipi(selectedSingle);
  }
  const baslangicTarihIslem = (e) => {
    setBaslangicTarihi(e.target.value);
  };
  const bitisTarihIslem = (e) => {
    setBitisTarihi(e.target.value);
  };
  function islemTipiIslem(selectedSingle) {
    setIslemTipi(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function kategoriIslem(selectedSingle) {
    setKategori(selectedSingle);
  }
  function kategoriDurumuIslem(selectedSingle) {
    setKategoriDurumu(selectedSingle);
  }
  const EkleIslem = async () => {
    // Anlık saat ve dakikayı almak için Date nesnesi oluşturun
    const now = new Date();
    const currentHours = now.getHours().toString().padStart(2, '0'); // Saat
    const currentMinutes = now.getMinutes().toString().padStart(2, '0'); // Dakika
    const currentSeconds = now.getSeconds().toString().padStart(2, '0'); // saniye
    // Saat ve dakikayı "HH:MM" formatında birleştirin
    const currentTime = `${currentHours}:${currentMinutes}:${currentSeconds}`;


    if (!firma || !hareketTipi || !islemKodu || !banka || !tutarMinRaw || !tutarMaxRaw ||
        !islemDurumu || !cariAdi || !cariTipi || !baslangicTarihi || !bitisTarihi
        || !dekontNo || !tcKimlikNoVergiNo || !islemTipi || !paraTransferKodu || !karsiIban
        || !paraBirimi || !kategori || !kategoriDurumu) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi < baslangicTarihi) {
      durationnotify("bitiş tarihi başlangıç tarihinden küçük olamaz!", 'danger');
      return;
    }
    const yeniHareket = [{
      firma: firma?.label,
      hareketTipi: hareketTipi.label,
      islemKodu,
      banka: banka?.label,
      tutarMin: tutarMinRaw,
      tutarMax: tutarMaxRaw,
      islemDurumu: islemDurumu?.label,
      cariAdi,
      cariTipi: cariTipi.label,
      aciklama,
      baslangicTarihi,
      bitisTarihi,
      dekontNo,
      tcKimlikNoVergiNo,
      islemTipi: islemTipi?.label,
      paraTransferKodu,
      karsiIban,
      paraBirimi: paraBirimi?.label,
      kategori: kategori.label,
      kategoriDurumu: kategoriDurumu.label,
    }]

    const apiVerisi = [
      {
        id: 1,
        islemKodu: "590710557",
        altKategori: "",
        banka: "VakıfBank",
        tutar: 384545.01,
        bakiye: 439545.10,
        pb: "TRY",
        tarih: baslangicTarihi,
        saat: currentTime,
        islemDurumu: "EŞLEŞME YAPILDI",
        islemTipi: "BANKA HAREKETİ",
        aciklama: "açıklama",
        cariAdi: "NEOVA SİGORTA A.Ş."
      },
    ]


    setHareketler([...hareketler, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/cari', yeniHareket);
    //   if (response.data.success) {
    //      setHareketler([...hareketler, yeniHareket]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // geçici buton
  const handleDelete = (index) => {
    const newHareketler = hareketler.filter((_, i) => i !== index);
    setHareketler(newHareketler);
    durationnotify("Kayıt başarıyla silindi.", 'info');
  };
  // const handleDelete = async (index) => {
  //   const itemToDelete = hareketler[index];
  //   try {
  //     const response = await axios.delete(`/api/hareketler/${itemToDelete.id}`);
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, i) => i !== index);
  //       setHareketler(newHareketler);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  const totalPages = Math.ceil(hareketler.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedHareketler = hareketler.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = displayedHareketler.map((_, index) => (currentPage - 1) * itemsPerPage + index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // geçici buton
  const handleBulkDelete = () => {
    const newHareketler = hareketler.filter((_, index) => !selectedItems.includes(index));
    setHareketler(newHareketler);
    setSelectedItems([]);
    durationnotify("Seçilen kayıtlar başarıyla silindi.", 'info');
  };
  // const handleBulkDelete = async () => {
  //   const itemsToDelete = selectedItems.map(index => hareketler[index]);
  //   try {
  //     const response = await axios.post('/api/hareketler/bulk-delete', { items: itemsToDelete });
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, index) => !selectedItems.includes(index));
  //       setHareketler(newHareketler);
  //       setSelectedItems([]);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  const toggleModal = (status, data) => {
    setModal(status);
    setModalData(data);
  };

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  const handleTutarChange = (setter, rawSetter) => (e) => {
    const { value } = e.target;
    // Sadece rakam, virgül ve nokta izin ver, diğer karakterleri kaldır
    const cleanedValue = value.replace(/[^0-9.,]/g, '');
    setter(cleanedValue);

    // Ham değeri güncelle
    const rawValue = cleanedValue.replace(/\./g, '').replace(',', '.');
    rawSetter(rawValue);
  };

  const handleTutarBlur = (setter, rawSetter) => (e) => {
    let { value } = e.target;

    // Eğer giriş boşsa, setter'i boş bir değer ile güncelle
    if (!value) {
      setter("");
      rawSetter("");
      return;
    }

    // Sayısal değeri temizle ve parse et
    const numberValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
    if (isNaN(numberValue)) {
      setter("");
      rawSetter("");
    } else {
      rawSetter(numberValue.toFixed(2)); // Orijinal değeri state'e kaydet
      const formattedValue = numberValue.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      setter(formattedValue);
    }
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Kategori Bazlı Hesap Hareketleri")} pageTitle={t("Nakit Yönetimi")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*tarih ve filtreleme*/}
                      <Row className="mt-1 mb-3">
                        <Col xs={12}>
                          <Row>
                            <Col xs={8} className="d-flex align-items-center">
                              <div className="d-flex">
                                <div className="d-flex py-2 px-3 me-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                                  <div className="me-2" style={{fontWeight: 500}}>
                                    TARİH - BAŞLANGIÇ:
                                  </div>
                                  <div className="text-danger" style={{fontWeight: 500}}>
                                    {baslangicTarihi ? formatDateString(baslangicTarihi) : "-"}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={4} className="d-flex justify-content-end">
                              <button className="btn btn-primary p-0 m-0" style={{width: 40, height: 40}} onClick={() => toggleModal(true, "FİLTRELE")}>
                                <i className="ri-filter-2-fill" style={{ fontSize: '24px' }}></i>
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>
                          {/* Hareket Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="hareketTipi" className="form-label text-capitalize">
                              Hareket Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hareketTipi}
                                onChange={hareketTipiIslem}
                                options={HareketTipiData}
                            />
                          </div>
                          {/* İşlem Kodu input */}
                          <div className="mb-3">
                            <Label htmlFor="islemKodu" className="form-label text-capitalize">
                              İşlem Kodu
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="islemKodu"
                                placeholder="İşlem Kodu"
                                value={islemKodu}
                                onChange={(e) => setIslemKodu(e.target.value)}
                            />
                          </div>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>
                          {/* Tutar Min ve Tutar Max input */}
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <Label htmlFor="tutarMin" className="form-label text-capitalize">
                                  Tutar Min
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control text-capitalize"
                                    id="tutarMin"
                                    placeholder="Tutar Min"
                                    value={tutarMin}
                                    onChange={handleTutarChange(setTutarMin, setTutarMinRaw)}
                                    onBlur={handleTutarBlur(setTutarMin, setTutarMinRaw)}
                                />
                              </Col>
                              <Col>
                                <Label htmlFor="tutarMax" className="form-label text-capitalize">
                                  Tutar Max
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control text-capitalize"
                                    id="tutarMax"
                                    placeholder="Tutar Max"
                                    value={tutarMax}
                                    onChange={handleTutarChange(setTutarMax, setTutarMaxRaw)}
                                    onBlur={handleTutarBlur(setTutarMax, setTutarMaxRaw)}
                                />
                              </Col>
                            </Row>
                          </div>
                          {/* İşlem Durumu select */}
                          <div className="mb-3">
                            <Label htmlFor="islemDurumu" className="form-label text-capitalize">
                              İşlem Durumu
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={islemDurumu}
                                onChange={islemDurumuIslem}
                                options={IslemDurumuData}
                            />
                          </div>
                          {/* Cari Adı input */}
                          <div className="mb-3">
                            <Label htmlFor="cariAdi" className="form-label text-capitalize">
                              Cari Adı
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="cariAdi"
                                placeholder="Cari Adı"
                                value={cariAdi}
                                onChange={(e) => setCariAdi(e.target.value)}
                            />
                          </div>
                          {/* Cari Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="cariTipi" className="form-label text-capitalize">
                              Cari Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={cariTipi}
                                onChange={cariTipiIslem}
                                options={CariTipiData}
                            />
                          </div>
                          {/* Açıklama input */}
                          <div className="mb-3">
                            <Label htmlFor="aciklama" className="form-label text-capitalize">
                              Açıklama
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="aciklama"
                                placeholder="Açıklama"
                                value={aciklama}
                                onChange={(e) => setAciklama(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi"
                                    value={baslangicTarihi}
                                    onChange={baslangicTarihIslem}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi"
                                    value={bitisTarihi}
                                    onChange={bitisTarihIslem}
                                />
                              </Col>
                            </Row>
                          </div>
                          {/* Dekont No input */}
                          <div className="mb-3">
                            <Label htmlFor="dekontNo" className="form-label text-capitalize">
                              Dekont No
                            </Label>
                            <Input
                                type="number"
                                className="form-control text-capitalize"
                                id="dekontNo"
                                placeholder="Dekont No"
                                value={dekontNo}
                                onChange={(e) => setDekontNo(e.target.value)}
                            />
                          </div>
                          {/* TC Kimlik No / Vergi No input */}
                          <div className="mb-3">
                            <Label htmlFor="tcKimlikNoVergiNo" className="form-label text-capitalize">
                              TC Kimlik No / Vergi No
                            </Label>
                            <Input
                                min={8}
                                max={11}
                                type="number"
                                className="form-control text-capitalize"
                                id="tcKimlikNoVergiNo"
                                placeholder="TC Kimlik No / Vergi No"
                                value={tcKimlikNoVergiNo}
                                onChange={(e) => setTcKimlikNoVergiNo(e.target.value)}
                            />
                          </div>
                          {/* İşlem Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="islemTipi" className="form-label text-capitalize">
                              İşlem Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={islemTipi}
                                onChange={islemTipiIslem}
                                options={IslemTipiData}
                            />
                          </div>
                          {/* Para Transfer Kodu input */}
                          <div className="mb-3">
                            <Label htmlFor="paraTransferKodu" className="form-label text-capitalize">
                              Para Transfer Kodu
                            </Label>
                            <Input
                                type="number"
                                className="form-control text-capitalize"
                                id="paraTransferKodu"
                                placeholder="Para Transfer Kodu"
                                value={paraTransferKodu}
                                onChange={(e) => setParaTransferKodu(e.target.value)}
                            />
                          </div>
                          {/* Karşı IBAN input */}
                          <div className="mb-3">
                            <Label htmlFor="karsiIban" className="form-label text-capitalize">
                              Karşı IBAN
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="karsiIban"
                                placeholder="Karşı IBAN"
                                value={karsiIban}
                                onChange={(e) => setKarsiIban(e.target.value)}
                            />
                          </div>
                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>
                          {/* Kategori select */}
                          <div className="mb-3">
                            <Label htmlFor="kategori" className="form-label text-capitalize">
                              Kategori
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={kategori}
                                onChange={kategoriIslem}
                                options={KategoriData}
                            />
                          </div>
                          {/* Kategori Durumu select */}
                          <div className="mb-3">
                            <Label htmlFor="kategoriDurumu" className="form-label text-capitalize">
                              Kategori Durumu
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={kategoriDurumu}
                                onChange={kategoriDurumuIslem}
                                options={KategoriDurumuData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div>
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}>
                              <div className="form-check">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                    onChange={handleSelectAll}
                                    checked={selectedItems.length === displayedHareketler.length}
                                />
                              </div>
                            </th>
                            <th className="text-capitalize">İşlem Kodu</th>
                            <th className="text-capitalize">Kategori</th>
                            <th className="text-capitalize">Alt Kategori</th>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">Tutar</th>
                            <th className="text-capitalize">Bakiye</th>
                            <th className="text-uppercase">P.B.</th>
                            <th className="text-capitalize">Tarih</th>
                            <th className="text-capitalize">İşlem Durumu</th>
                            <th className="text-capitalize">İşlem Tipi</th>
                            <th className="text-capitalize">Açıklama</th>
                            <th className="text-capitalize">Cari Adı</th>
                          </tr>
                          </thead>
                          {displayedHareketler.length >= 1 && (
                              <tbody>
                              {displayedHareketler.map((hareket, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td>
                                        <div className="form-check">
                                          <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="chk_child"
                                              value="option1"
                                              checked={isChecked}
                                              onChange={() => handleSelectItem(globalIndex)}
                                          />
                                        </div>
                                      </td>
                                      <td>{hareket.islemKodu}</td>
                                      <td className="text-capitalize">
                                        <Button color="info" onClick={() => handleDelete(globalIndex)}>Ekle</Button>
                                      </td>
                                      <td>{hareket.altKategori}</td>
                                      <td className="text-uppercase">{hareket.banka}</td>
                                      <td>{formatCurrency(hareket.tutar)}</td>
                                      <td>{formatCurrency(hareket.bakiye)}</td>
                                      <td className="text-uppercase">{hareket.pb}</td>
                                      <td>{formatDateString(hareket.tarih) + " / " + hareket.saat}</td>
                                      <td className="text-uppercase">{hareket.islemDurumu}</td>
                                      <td className="text-uppercase">{hareket.islemTipi}</td>
                                      <td className="text-capitalize">{hareket.aciklama}</td>
                                      <td className="text-uppercase">{hareket.cariAdi}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedHareketler.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <Col xs={12} className="mt-3">
                        <Row className="d-flex justify-content-between ">
                          <Col xs={12} md={8}>
                            <Button color="info" className="me-2 mb-2 mb-md-0 text-uppercase" onClick={handleBulkDelete} disabled={selectedItems.length === 0}>KATEGORİ EKLE</Button>
                            <Button color="danger" className="me-2 mb-2 mb-md-0 text-uppercase" onClick={handleBulkDelete} disabled={selectedItems.length === 0}>KATEGORİ KALDIR</Button>
                          </Col>
                          <Col xs={12} md={4} className="d-flex justify-content-end mt-4 mt-md-0">
                            <ul className="pagination mb-0">
                              <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                              </li>
                              {[...Array(totalPages)].map((_, pageIndex) => (
                                  <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                    <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                  </li>
                              ))}
                              <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                                <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>

        <Modal isOpen={modal} toggle={() => toggleModal(false, "")}>
          <ModalHeader toggle={() => toggleModal(false, "")}>{modalData}</ModalHeader>
          <ModalBody>
            {/* Modal içeriği buraya gelecek */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal(false, "")}>Kapat</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default KategoriBazliHesapHareketleri;
