import React, {useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const HareketTipiData = [
    {value: "ALACAK", label: "ALACAK"},
    {value: "BORÇ", label: "BORÇ"},
];
const IslemTipiData = [
    {value: "NAKİT", label: "NAKİT"},
    {value: "KREDİ KARTI", label: "KREDİ KARTI"},
];
const KullaniciData = [
    {value: "HEKİM BİLİŞİM", label: "HEKİM BİLİŞİM"},
];
const ParaBirimiData = [
    {value: "TRY", label: "TRY"},
    {value: "USD", label: "USD"},
];

const YeniIslem = () => {
    const { t } = useTranslation();
    document.title = t("Yeni İşlem");

    const [cariAdi, setCariAdi] = useState("");
    const [hareketTipi, setHareketTipi] = useState(null);
    const [islemTipi, setIslemTipi] = useState(null);
    const [kullanici, setKullanici] = useState(null);
    const [paraBirimi, setParaBirimi] = useState(null);
    const [tutar, setTutar] = useState("");
    const [tutarRaw, setTutarRaw] = useState("");
    const [tarih, setTarih] = useState("");
    const [dekontNo, setDekontNo] = useState("");
    const [aciklama, setAciklama] = useState("");
    const [smsGonder, setSmsGonder] = useState(true);
    const [mailGonder, setMailGonder] = useState(true);

    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState("");

    const durationnotify = (message, type = 'success') => toast(message, {
        position: "top-right",
        hideProgressBar: true,
        className: `bg-${type} text-white`,
        autoClose: 5000
    });


    const handleSubmit = () => {
        if (!cariAdi || !hareketTipi || !islemTipi || !kullanici || !paraBirimi || !tutarRaw ||
            !tarih || !dekontNo) {
            durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
            return;
        }
        const yeniIslem = {
           cariAdi,
            hareketTipi: hareketTipi.label,
            islemTipi: islemTipi.label,
            kullanici: kullanici.label,
            paraBirimi: paraBirimi.label,
            tutar: tutarRaw,
            tarih,
            dekontNo,
            aciklama,
            smsGonder,
            mailGonder
        };
        durationnotify("Kayıt başarıyla eklendi!", 'success');

        // aşağıdaki kısım api hazır olunca kullanılacak kısım
        // try {
        //   const response = await axios.post('/api/yeni-islem', yeniIslem);
        //   if (response.data.success) {
        //      console.log("başarılı");
        //   } else {
        //     console.error('Error: Data was not added.');
        //   }
        // } catch (error) {
        //   console.error('There was an error adding the data!', error);
        // }
    };

    const handleTutarChange = (setter, rawSetter) => (e) => {
        const { value } = e.target;
        // Sadece rakam, virgül ve nokta izin ver, diğer karakterleri kaldır
        const cleanedValue = value.replace(/[^0-9.,]/g, '');
        setter(cleanedValue);

        // Ham değeri güncelle
        const rawValue = cleanedValue.replace(/\./g, '').replace(',', '.');
        rawSetter(rawValue);
    };

    const handleTutarBlur = (setter, rawSetter) => (e) => {
        let { value } = e.target;

        // Eğer giriş boşsa, setter'i boş bir değer ile güncelle
        if (!value) {
            setter("");
            rawSetter("");
            return;
        }

        // Sayısal değeri temizle ve parse et
        const numberValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
        if (isNaN(numberValue)) {
            setter("");
            rawSetter("");
        } else {
            rawSetter(numberValue.toFixed(2)); // Orijinal değeri state'e kaydet
            const formattedValue = numberValue.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            setter(formattedValue);
        }
    };

    const toggleModal = (status, data) => {
        setModal(status);
        setModalData(data);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Yeni İşlem")} pageTitle={t("Hesap İşlemleri")} pageLink="online-banka"/>
                    <Row className="justify-content-center">
                        <ToastContainer />

                        <Col lg={12}>
                            <Card>
                                <Col xs={12}>
                                    <CardBody>
                                        {/*cari adı input ve hareket tipi select*/}
                                        <Row className="mt-0 mb-0 mt-lg-2 mb-lg-3">
                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Row>
                                                    <Col xs={11}>
                                                        <Label for="cariAdi" className="form-label text-capitalize">Cari Adı</Label>
                                                        <Input type="text" id="cariAdi" value={cariAdi} className="text-capitalize"
                                                               placeholder="Cari Adı"
                                                               onChange={(e) => setCariAdi(e.target.value)}/>
                                                    </Col>
                                                    <Col xs={1} className="d-flex justify-content-center align-items-center" onClick={() => toggleModal(true, "CARİ GETİR")}>
                                                        <i className="mdi mdi-content-copy mt-4 scale-animation-icon" style={{fontSize: "20px", color: "#f06548"}}></i>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="hareketTipi" className="form-label text-capitalize">Hareket Tipi</Label>
                                                <Select id="hareketTipi"
                                                        className="text-capitalize" value={hareketTipi} onChange={setHareketTipi}
                                                        options={HareketTipiData}/>
                                            </Col>
                                        </Row>

                                        {/*işlem tipi select ve kullanıcı select*/}
                                        <Row className="my-0 my-lg-3">
                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="islemTipi" className="form-label text-capitalize">İşlem Tipi</Label>
                                                <Select id="islemTipi"
                                                        className="text-capitalize"
                                                        value={islemTipi} onChange={setIslemTipi}
                                                        options={IslemTipiData}/>
                                            </Col>

                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="kullanici" className="form-label text-capitalize">Kullanıcı</Label>
                                                <Select id="kullanici"
                                                        className="text-capitalize"
                                                        value={kullanici} onChange={setKullanici}
                                                        options={KullaniciData}/>
                                            </Col>
                                        </Row>

                                        {/*para birimi select ve tutar input*/}
                                        <Row className="my-0 my-lg-3">
                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="paraBirimi" className="form-label text-capitalize">Para Birimi</Label>
                                                <Select id="paraBirimi"
                                                        className="text-capitalize"
                                                        value={paraBirimi} onChange={setParaBirimi}
                                                        options={ParaBirimiData}/>
                                            </Col>

                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="tutar" className="form-label text-capitalize">Tutar</Label>
                                                <Input type="text"
                                                       id="tutar"
                                                       value={tutar}
                                                       placeholder="Tutar"
                                                       className="text-capitalize"
                                                       onChange={handleTutarChange(setTutar, setTutarRaw)}
                                                       onBlur={handleTutarBlur(setTutar, setTutarRaw)}/>
                                            </Col>
                                        </Row>

                                        {/*tarih ve dekont no inputları*/}
                                        <Row className="my-0 my-lg-3">
                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="tarih" className="form-label text-capitalize">Tarih</Label>
                                                <Input type="date" id="tarih" value={tarih} onChange={(e) => setTarih(e.target.value)}/>
                                            </Col>

                                            <Col xs={12} lg={6} className="my-2 my-lg-0">
                                                <Label for="dekontNo" className="form-label text-capitalize">Dekont No</Label>
                                                <Input type="text" id="dekontNo" value={dekontNo}
                                                       placeholder="Dekont No"
                                                       className="text-capitalize"
                                                       onChange={(e) => setDekontNo(e.target.value)}/>
                                            </Col>
                                        </Row>

                                        {/*açıklama input*/}
                                        <div className="mt-3 mb-2">
                                            <Label for="aciklama" className="form-label text-capitalize">Açıklama</Label>
                                            <Input type="textarea" id="aciklama" value={aciklama}
                                                   placeholder="Açıklama"
                                                   className="text-capitalize"
                                                   onChange={(e) => setAciklama(e.target.value)}/>
                                        </div>

                                        {/*sms gönder ve mail gönder checkbox*/}
                                        <div className="mt-3 mb-2">
                                            <div>
                                                <Input type="checkbox" checked={smsGonder} id="sms-gonder"
                                                       className="text-capitalize"
                                                       onChange={(e) => setSmsGonder(e.target.checked)}/>
                                                <Label style={{marginLeft: 5}} for="sms-gonder" className="form-label text-uppercase">SMS Gönder</Label>
                                            </div>


                                            <div>
                                                <Input type="checkbox" checked={mailGonder}
                                                       className="pl-2 text-capitalize"
                                                       id="mail-gonder"
                                                       onChange={(e) => setMailGonder(e.target.checked)}/>
                                                <Label style={{marginLeft: 5}} for="mail-gonder" className="form-label text-uppercase">Mail Gönder</Label>
                                            </div>
                                        </div>

                                        {/*ekle buton*/}
                                        <div className="text-start">
                                            <button type="submit" className="btn btn-primary my-2 text-capitalize" onClick={handleSubmit}>
                                                Ekle
                                            </button>
                                        </div>
                                    </CardBody>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={modal} toggle={() => toggleModal(false, "")}>
                <ModalHeader toggle={() => toggleModal(false, "")}>{modalData}</ModalHeader>
                <ModalBody>
                    {/* Modal içeriği buraya gelecek */}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal(false, "")}>Kapat</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default YeniIslem;
