import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GunSonuBakiyeRaporuChart from "./Chart/GunSonuBakiyeRaporuChart";
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const HesapTuruData = [
  { value: "vadeli", label: "VADELİ" },
  { value: "vadesiz", label: "VADESİZ" },
];
const HesapAdiData = [
  { value: "hesap1", label: "Hesap 1" },
  { value: "hesap2", label: "Hesap 2" },
];
const ParaBirimiData = [
  { value: "tl", label: "TL" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];
const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];


const GunSonuBakiyeRaporu = () => {
  const { t } = useTranslation();
  document.title = t("Gün Sonu Bakiye Raporu");

  const [firma, setFirma] = useState(null);
  const [banka, setBanka] = useState(null);
  const [sube, setSube] = useState("");
  const [hesapAdi, setHesapAdi] = useState(null);
  const [hesapTuru, setHesapTuru] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");
  const [records, setRecords] = useState([]);

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function hesapTuruIslem(selectedSingle) {
    setHesapTuru(selectedSingle);
  }
  function hesapAdiIslem(selectedSingle) {
    setHesapAdi(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }
  const baslangicTarihiIslem = (e) => {
    setBaslangicTarihi(e.target.value);
  };
  const bitisTariIslem = (e) => {
    setBitisTarihi(e.target.value);
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!firma || !banka || !sube || !hesapTuru || !hesapAdi || !paraBirimi || !durum || !baslangicTarihi || !bitisTarihi) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi < baslangicTarihi) {
      durationnotify("bitiş tarihi başlangıç tarihinden küçük olamaz!", 'danger');
      return;
    }
    const yeniKayit = {
      firma: firma.label,
      banka: banka.label,
      sube,
      hesapTuru: hesapTuru.label,
      hesapAdi: hesapAdi.label,
      paraBirimi: paraBirimi.label,
      durum: durum.label,
      baslangicTarihi,
      bitisTarihi
    };

    let apiVerisi = [
      {
        bakiyeTarihi: "07.04.2024",
        toplamBakiye: 15182192.50,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "08.04.2024",
        toplamBakiye: 1686732.15,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "09.04.2024",
        toplamBakiye: 1689474.46,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "10.04.2024",
        toplamBakiye: 1688594.46,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "11.04.2024",
        toplamBakiye: 1687844.46,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "12.04.2024",
        toplamBakiye: 1677795.29,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "13.04.2024",
        toplamBakiye: 1677795.29,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "14.04.2024",
        toplamBakiye: 1677795.29,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "15.04.2024",
        toplamBakiye: 492023.80,
        pb: "TURK LİRASI"
      },
      {
        bakiyeTarihi: "16.04.2024",
        toplamBakiye: 589233.60,
        pb: "TURK LİRASI"
      }
      ]

    setRecords([...records, apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/gun-sonu-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Gün Sonu Bakiye Raporu")} pageTitle={t("Özet Raporlar")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* veri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>
                          {/* Şube input */}
                          <div className="mb-3">
                            <Label htmlFor="sube" className="form-label text-capitalize">
                              Şube
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="sube"
                                placeholder="Şube"
                                value={sube}
                                onChange={(e) => setSube(e.target.value)}
                            />
                          </div>

                          {/* Hesap Türü select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapTuru" className="form-label text-capitalize">
                              Hesap Türü
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapTuru}
                                onChange={hesapTuruIslem}
                                options={HesapTuruData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="startDate"
                                    value={baslangicTarihi}
                                    onChange={baslangicTarihiIslem}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="endDate"
                                    value={bitisTarihi}
                                    onChange={bitisTariIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Hesap Adı select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapAdi" className="form-label text-capitalize">
                              Hesap Adı
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapAdi}
                                onChange={hesapAdiIslem}
                                options={HesapAdiData}
                            />
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>

                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="durum" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>


              {/* chart */}
              <Row>
                <Col lg={12}>
                  <Card style={{height: 400}}>
                    <CardBody>
                      <GunSonuBakiyeRaporuChart apiVerisi={records.length > 0 ? records[records.length - 1] : []}/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th style={{width: 55}}></th>
                            <th className="text-capitalize">Bakiye Tarihi</th>
                            <th className="text-capitalize">Toplam Bakiye</th>
                            <th className="text-uppercase">P.B</th>
                          </tr>
                          </thead>

                          {records.length >= 1 && (
                              <tbody>
                              {records[0].map((records, index) => {
                                return (
                                    <tr key={index}>
                                      <td className="d-flex justify-content-center">
                                        <i className="bx bx-plus" style={{fontSize: "20px"}}></i>
                                      </td>
                                      <td>{records.bakiyeTarihi}</td>
                                      <td>{formatCurrency(records.toplamBakiye)}</td>
                                      <td className="text-uppercase">{records.pb}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {records.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default GunSonuBakiyeRaporu;
