import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container, Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const KaynakData = [
  { value: "kaynak1", label: "Kaynak 1" },
  { value: "kaynak2", label: "Kaynak 2" },
];

const KurBilgisi = () => {
  const { t } = useTranslation();
  document.title = t("Kur Bilgisi");

  const [kaynak, setKaynak] = useState(null);
  const [tarih, setTarih] = useState("");
  const [saat, setSaat] = useState("");
  const [records, setRecords] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });


  function kaynakIslem(selectedSingle) {
    setKaynak(selectedSingle);
  }
  const tarihiIslem = (e) => {
    setTarih(e.target.value);
  };

  const EkleIslem = async () => {
    // Anlık saat ve dakikayı almak için Date nesnesi oluşturun
    const now = new Date();
    const currentHours = now.getHours().toString().padStart(2, '0'); // Saat
    const currentMinutes = now.getMinutes().toString().padStart(2, '0'); // Dakika
    // Saat ve dakikayı "HH:MM" formatında birleştirin
    const currentTime = `${currentHours}:${currentMinutes}`;

    const date = new Date(tarih);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let tarihFormat = day + "." + month + "." + year;


    if (!kaynak || !tarih) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    setSaat(currentTime);

    const yeniKayit = {
      kaynak: kaynak.label,
      tarih,
      saat,
    };

    let apiVerisi = [
      {
        paraBirimiKisaHali: "AED",
        paraBirimiUzunHali: "BAE DİRHEM",
        kaynak1: "SERBEST PİYASA",
        kurTarihi:  tarihFormat + " " + currentTime,
        alisFiyati: 8.8534,
        satisFiyati: 8.8572,
        guncellemeTarihi: tarihFormat + " " + currentTime
      },
    ]

    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/kur-bilgisi', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Kur Bilgisi")} pageTitle={t("Bir Bakışta")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* veri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Kaynak select */}
                          <div className="mb-3">
                            <Label htmlFor="kaynak" className="form-label text-capitalize">
                              Kaynak
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={kaynak}
                                onChange={kaynakIslem}
                                options={KaynakData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="tarihi"
                                    value={tarih}
                                    onChange={tarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Para Birimi</th>
                            <th className="text-capitalize">Para Birimi</th>
                            <th className="text-capitalize">Kaynak</th>
                            <th className="text-capitalize">Kur Tarihi</th>
                            <th className="text-capitalize">Alış Fiyatı</th>
                            <th className="text-capitalize">Satış Fiyatı</th>
                            <th className="text-capitalize">Güncelleme Tarihi</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.paraBirimiKisaHali}</td>
                                      <td className="text-uppercase">{records.paraBirimiUzunHali}</td>
                                      <td className="text-uppercase">{records.kaynak1}</td>
                                      <td>{records.kurTarihi}</td>
                                      <td>{formatCurrency(records.alisFiyati, 4)}</td>
                                      <td>{formatCurrency(records.satisFiyati, 4)}</td>
                                      <td>{records.guncellemeTarihi}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default KurBilgisi;
