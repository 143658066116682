import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const IslemTipiData = [
  { value: "islemTipi1", label: "İşlem Tipi 1" },
  { value: "islemTipi2", label: "İşlem Tipi 2" },
];
const HareketTipiData = [
  { value: "hareketTipi1", label: "Hareket Tipi 1" },
  { value: "hareketTipi2", label: "Hareket Tipi 2" },
];
const ParaBirimiData = [
  { value: "tl", label: "TÜRK LİRASI" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];

const CariRaporu = () => {
  const { t } = useTranslation();
  document.title = t("Cari Raporu");

  const [paraTransferKodu, setParaTransferKodu] = useState("");
  const [cariAdi, setCariAdi] = useState("");
  const [firma, setFirma] = useState(null);
  const [hareketTipi, setHareketTipi] = useState(null);
  const [islemTipi, setIslemTipi] = useState(null);
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");
  const [paraBirimi, setParaBirimi] = useState(null);
  const [records, setRecords] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function hareketTipiIslem(selectedSingle) {
    setHareketTipi(selectedSingle);
  }
  function islemTipiIslem(selectedSingle) {
    setIslemTipi(selectedSingle);
  }
  const baslangicTarihiIslem = (e) => {
    setBaslangicTarihi(e.target.value);
  };
  const bitisTarihiIslem = (e) => {
    setBitisTarihi(e.target.value);
  };
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }

  const EkleIslem = async () => {
    if (!paraTransferKodu || !cariAdi || !firma || !islemTipi || !baslangicTarihi || !bitisTarihi || !hareketTipi || !paraBirimi) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi < baslangicTarihi) {
      durationnotify("bitiş tarihi başlangıç tarihinden küçük olamaz!", 'danger');
      return;
    }
    const yeniKayit = {
      paraTransferKodu,
      cariAdi,
      firma: firma.label,
      islemTipi: islemTipi.label,
      baslangicTarihi,
      bitisTarihi,
      hareketTipi: hareketTipi.label,
      paraBirimi: paraBirimi.label,
    };

    const apiVerisi = [
      {
        paraTransferKodu: null,
        cariAdi: "EŞLEŞME YAPILAMAYAN HAREKETLER",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 3,
        alacakTutari: 51539.43,
        toplamIslemAdedi: 3,
        toplamTutar: 51539.43
      },
      {
        paraTransferKodu: "PN881924730136",
        cariAdi: "CORPUS SİGORTA A.Ş.",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 13,
        alacakTutari: 53457.04,
        toplamIslemAdedi: 13,
        toplamTutar: 53457.04
      },
      {
        paraTransferKodu: "PN88192479822",
        cariAdi: "ETHICA SİGORTA A.Ş.",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 2,
        alacakTutari: 111543.56,
        toplamIslemAdedi: 2,
        toplamTutar: 111543.56
      },
      {
        paraTransferKodu: "PN881924425430",
        cariAdi: "EUREKO SİGORTA A.Ş.",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 3,
        alacakTutari: 5671.35,
        toplamIslemAdedi: 3,
        toplamTutar: 5671.35
      },
      {
        paraTransferKodu: "PN881924397540",
        cariAdi: "NEOVA SİGORTA A.Ş.",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 1,
        alacakTutari: 384688.64,
        toplamIslemAdedi: 1,
        toplamTutar: 384688.64
      },
      {
        paraTransferKodu: "PN881744695518",
        cariAdi: "OPTİMUM OTOMOTİV",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 1,
        alacakTutari: 350363.38,
        toplamIslemAdedi: 1,
        toplamTutar: 350363.38
      },
      {
        paraTransferKodu: "PN881924463432",
        cariAdi: "TUNALAR MOTORLU ARAÇLAR TİC. A.Ş.",
        borcAdedi: 0,
        borcTutari: 0.00,
        alacakAdedi: 1,
        alacakTutari: 1000000.00,
        toplamIslemAdedi: 1,
        toplamTutar: 1000000.00
      },
    ];


    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/yeni-yuruyen-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const calculateTotal = (records, field, isCurrency = false) => {
    if (!Array.isArray(records) || records.length === 0) return isCurrency ? '0,00' : '0';
    const total = records.reduce((acc, record) => {
      const value = isCurrency ? parseFloat(record[field] || 0) : parseInt(record[field] || 0, 10);
      return acc + value;
    }, 0);
    return isCurrency ? total.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : total.toLocaleString('tr-TR');
  };

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Cari Raporu")} pageTitle={t("Özet Raporlar")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Para Transfer Kodu input */}
                          <div className="mb-3">
                            <Label htmlFor="paraTransferKodu" className="form-label text-capitalize">
                              Para Transfer Kodu
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="paraTransferKodu"
                                placeholder="Para Transfer Kodu"
                                value={paraTransferKodu}
                                onChange={(e) => setParaTransferKodu(e.target.value)}
                            />
                          </div>

                          {/* Cari Adı input */}
                          <div className="mb-3">
                            <Label htmlFor="cariAdi" className="form-label text-capitalize">
                              Cari Adı
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="cariAdi"
                                placeholder="Cari Adı"
                                value={cariAdi}
                                onChange={(e) => setCariAdi(e.target.value)}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* İşlem Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="islemTipi" className="form-label text-capitalize">
                              İşlem Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={islemTipi}
                                onChange={islemTipiIslem}
                                options={IslemTipiData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi"
                                    value={baslangicTarihi}
                                    onChange={baslangicTarihiIslem}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi"
                                    value={bitisTarihi}
                                    onChange={bitisTarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Hareket Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="hareketTipi" className="form-label text-capitalize">
                              Hareket Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hareketTipi}
                                onChange={hareketTipiIslem}
                                options={HareketTipiData}
                            />
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3">
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Para Transfer Kodu</th>
                            <th className="text-capitalize">Cari Adı</th>
                            <th className="text-capitalize">Borç Adedi</th>
                            <th className="text-capitalize">Borç Tutarı</th>
                            <th className="text-capitalize">Alacak Adedi</th>
                            <th className="text-capitalize">Alacak Tutarı</th>
                            <th className="text-capitalize">Toplam İşlem Adedi</th>
                            <th className="text-capitalize">Toplam Tutar</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.paraTransferKodu}</td>
                                      <td className="text-uppercase">{records.cariAdi}</td>
                                      <td>{records.borcAdedi}</td>
                                      <td>{formatCurrency(records.borcTutari)}</td>
                                      <td>{records.alacakAdedi}</td>
                                      <td>{formatCurrency(records.alacakTutari)}</td>
                                      <td>{records.toplamIslemAdedi}</td>
                                      <td>{formatCurrency(records.toplamTutar)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-end align-items-center mt-3">
                        {displayedRecords.length >= 1 && (
                            <div className="me-3 text-capitalize">
                              Toplam Tutar: {calculateTotal(records, 'toplamTutar', true)}
                            </div>
                        )}
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default CariRaporu;
