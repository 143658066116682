import React from 'react';
import {Bar} from 'react-chartjs-2';
import {Col, Row} from "reactstrap";

const apiVerisi = [
    {"date": "2024 Şubat", "value": 516541732.59},
    {"date": "2024 Mart", "value": 344397696.42},
    {"date": "2024 Nisan", "value": 266122430.22},
];


const AylikRaporChart = ({apiVerisi}) => {

    const colors = [
        'rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)',
        'rgba(201, 203, 207, 0.6)', 'rgba(83, 102, 255, 0.6)', 'rgba(200, 100, 200, 0.6)',
        'rgba(100, 159, 255, 0.6)'
    ];

    const data = {
        labels: apiVerisi.map(item => item.ay),
        datasets: [{
            label: 'Gün Sonu Toplam Bakiye',
            data: apiVerisi.map(item => item.toplamTutar),
            backgroundColor: colors,
            borderColor: colors.map(color => color.replace('0.6', '1')),
            borderWidth: 1.5,
            hoverBackgroundColor: colors.map(color => color.replace('0.6', '0.8')),
            hoverBorderColor: colors.map(color => color.replace('0.6', '1.0')),
            barPercentage: 0.5,
            categoryPercentage: 0.8
        }]
    };


    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.raw.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'
                    })}`,
                },
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => value.toLocaleString('tr-TR')
                },
                grid: {
                    borderDash: [5, 5]
                }
            },
            x: {
                grid: {
                    display: false
                }
            }
        },
        elements: {
            bar: {
                borderRadius: {topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0}, // Specific radius for top corners
                borderSkipped: false
            }
        },
        maintainAspectRatio: false
    };


    return (
        <div style={{width: "100%", height: "330px"}}>
            <Row>
                <Col xs={12} className="d-flex justify-content-center align-items-center my-2">
                    <h4 className="text-capitalize">Aylık Rapor</h4>
                </Col>
            </Row>
            <Bar data={data} options={options}/>
        </div>
    )
};

export default AylikRaporChart;
