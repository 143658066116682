import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const TipData = [
  { value: "tip1", label: "Tip 1" },
  { value: "tip2", label: "Tip 2" },
];
const ParaBirimiData = [
  { value: "tl", label: "TÜRK LİRASI" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];

const GrupluHareketBakiyesi = () => {
  const { t } = useTranslation();
  document.title = t("Gruplu Hareket Bakiyesi");

  const [tip, setTip] = useState(null);
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");
  const [paraBirimi, setParaBirimi] = useState(null);
  const [records, setRecords] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function tipIslem(selectedSingle) {
    setTip(selectedSingle);
  }
  const baslangicTarihiIslem = (e) => {
    setBaslangicTarihi(e.target.value);
  };
  const bitisTarihiIslem = (e) => {
    setBitisTarihi(e.target.value);
  };
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }

  const EkleIslem = async () => {
    if (!tip || !baslangicTarihi || !bitisTarihi || !paraBirimi) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi < baslangicTarihi) {
      durationnotify("bitiş tarihi başlangıç tarihinden küçük olamaz!", 'danger');
      return;
    }
    const yeniKayit = {
      tip: tip.label,
      baslangicTarihi,
      bitisTarihi,
      paraBirimi: paraBirimi.label,
    };

    const apiVerisi = [
      {
        tip: "Başlangıç Bakiye",
        toplamTutar: 589233.60,
      },
      {
        tip: "Eşleşme Yapılamayan Hareketler",
        toplamTutar: -24237.11,
      },
      {
        tip: "Cari Tipi Olmayan Hareketler",
        toplamTutar: 1555360.59,
      },
      {
        tip: "Gün Sonu Bakiye",
        toplamTutar: 2120357.08,
      },
    ]


    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/yeni-yuruyen-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const calculateTotal = (records, field, isCurrency = false) => {
    if (!Array.isArray(records) || records.length === 0) return isCurrency ? '0,00' : '0';
    const total = records.reduce((acc, record) => {
      const value = isCurrency ? parseFloat(record[field] || 0) : parseInt(record[field] || 0, 10);
      return acc + value;
    }, 0);
    return isCurrency ? total.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : total.toLocaleString('tr-TR');
  };
  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Gruplu Hareket Bakiyesi")} pageTitle={t("Özet Raporlar")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Tip select */}
                          <div className="mb-3">
                            <Label htmlFor="tip" className="form-label text-capitalize">
                              Tip
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={tip}
                                onChange={tipIslem}
                                options={TipData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi"
                                    value={baslangicTarihi}
                                    onChange={baslangicTarihiIslem}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi"
                                    value={bitisTarihi}
                                    onChange={bitisTarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3">
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Tip</th>
                            <th className="text-capitalize">Toplam Tutar</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.tip}</td>
                                      <td>{formatCurrency(records.toplamTutar)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default GrupluHareketBakiyesi;
