import React from 'react';
import { Card, CardBody, Col, Row} from 'reactstrap';

import CountUp from "react-countup";

//Import Icons
import FeatherIcon from "feather-icons-react";

const ToplamChart = ({toplamTutar, toplamAdet}) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} key={"toplam"}>
                    <Card className={"card-animate"}
                          style={{
                              background: 'linear-gradient(to right, #09a278, #f78770)'
                          }}>
                        <CardBody>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fs-15 fw-medium mb-0 text-white text-uppercase">TOPLAM - BUGÜN</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value text-white" key={"toplam-span"}>
                                            <CountUp
                                                start={0}
                                                end={toplamTutar}
                                                decimals={2}
                                                separator=","
                                                duration={4}
                                                formattingFn={(value) => value.toLocaleString()}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-white text-capitalize">
                                        {/*<span className={"mb-0 badge bg-light text-success"}>*/}
                                        {/*    <i className={"align-middle ri-arrow-up-line"}></i> 16.24 %*/}
                                        {/*</span> */}
                                        {toplamAdet} Adet
                                    </p>
                                </div>
                                <div className="avatar-lg flex-shrink-0 d-flex justify-content-center align-items-center">
                                    <span className={"avatar-title rounded-circle fs-2 opacity-75 bg-transparent"}>
                                        <FeatherIcon
                                            size={50}
                                            icon="plus"
                                            className="text-white"
                                        />
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </React.Fragment>
    );
};

export default ToplamChart;
