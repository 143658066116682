import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Col, Row} from 'reactstrap';

const BreadCrumb = ({title, pageTitle, pageLink}) => {
    const history = useNavigate();

    return (
        <React.Fragment>

            <Col xs={12}>
                <div className="page-title-box">
                    <Row>
                        {pageLink && (
                            <Col xs={1} className="d-flex justify-content-center justify-content-md-start align-items-center btn" onClick={() =>  history(`/${pageLink}`)}>
                                <i className="bx bx-arrow-back la-2x"></i>
                            </Col>
                        )}

                        <Col className="d-sm-flex align-items-center justify-content-between p-0 m-0">
                            <h4 className="mb-sm-0">{title}</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to={`/${pageLink}`}>{pageTitle}</Link></li>
                                    <li className="breadcrumb-item active">{title}</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>

        </React.Fragment>
    );
};

export default BreadCrumb;
