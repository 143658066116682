import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const CariTipiData = [
  { value: "cariTipi1", label: "Cari Tipi 1" },
  { value: "cariTipi2", label: "Cari Tipi 2" },
];
const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const ParaBirimiData = [
  { value: "tl", label: "TÜRK LİRASI" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];

const CariTipiRaporu = () => {
  const { t } = useTranslation();
  document.title = t("Cari Tipi Raporu");

  const [cariTipi, setCariTipi] = useState(null);
  const [firma, setFirma] = useState(null);
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");
  const [paraBirimi, setParaBirimi] = useState(null);
  const [records, setRecords] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function cariTipiIslem(selectedSingle) {
    setCariTipi(selectedSingle);
  }
  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  const baslangicTarihiIslem = (e) => {
    setBaslangicTarihi(e.target.value);
  };
  const bitisTarihiIslem = (e) => {
    setBitisTarihi(e.target.value);
  };
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }


  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!cariTipi || !firma || !baslangicTarihi || !bitisTarihi || !paraBirimi) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi < baslangicTarihi) {
      durationnotify("bitiş tarihi başlangıç tarihinden küçük olamaz!", 'danger');
      return;
    }
    const yeniKayit = {
      cariTipi: cariTipi.label,
      firma: firma.label,
      baslangicTarihi,
      bitisTarihi,
      paraBirimi: paraBirimi.label,
    };

    const apiVerisi = [
      {
        cariTipi: "CARİ TİPİ OLMAYAN HAREKETLER",
        borcAdedi: 1,
        borcTutari: 350363.38,
        alacakAdedi: 21,
        alacakTutari: 1905723.97,
      },
      {
        cariTipi: "EŞLEŞME YAPILAMAYAN HAREKETLER",
        borcAdedi: 6,
        borcTutari: 75776.54,
        alacakAdedi: 3,
        alacakTutari: 51539.43,
      },
    ]


    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/yeni-yuruyen-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Cari Tipi Raporu")} pageTitle={t("Özet Raporlar")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Cari Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="cariTipi" className="form-label text-capitalize">
                              Cari Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={cariTipi}
                                onChange={cariTipiIslem}
                                options={CariTipiData}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi"
                                    value={baslangicTarihi}
                                    onChange={baslangicTarihiIslem}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi"
                                    value={bitisTarihi}
                                    onChange={bitisTarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3">
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Cari Tipi</th>
                            <th className="text-capitalize">Borç Adedi</th>
                            <th className="text-capitalize">Borç Tutarı</th>
                            <th className="text-capitalize">Alacak Adedi</th>
                            <th className="text-capitalize">Alacak Tutarı</th>
                            <th className="text-capitalize">Toplam İşlem Adedi</th>
                            <th className="text-capitalize">Toplam Tutar</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);

                                const netTutar = records.alacakTutari - records.borcTutari;
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.cariTipi}</td>
                                      <td>{records.borcAdedi}</td>
                                      <td>-{formatCurrency(records.borcTutari)}</td>
                                      <td>{records.alacakAdedi}</td>
                                      <td>{formatCurrency(records.alacakTutari)}</td>
                                      <td>{records.borcAdedi+records.alacakAdedi}</td>
                                      <td>{formatCurrency(netTutar)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default CariTipiRaporu;
