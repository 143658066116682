import React from "react";
import ReactEcharts from "echarts-for-react";

const data = [
    { value: 500000, name: 'VAKIFBANK' },
    { value: 100000, name: 'AKBANK' },
    { value: 50000, name: 'GARANTI BBVA' },
    { value: 20000, name: 'ZIRAAT BANKASI' },
    { value: 10000, name: 'YAPI KREDI' },
    { value: 5000, name: 'İŞ BANKASI' },
    { value: 2000, name: 'TEB' },
    { value: 1000, name: 'KUVEYTTÜRK' },
];

const colorPalette = ['#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4db6ac', '#81c784'];

const getRandomColor = () => {
    return colorPalette[Math.floor(Math.random() * colorPalette.length)];
}

const OnlineBankaBakiyeChart = () => {
    const option = {
        title: {
            text: 'ONLINE BANKA BAKIYE',
            subtext: '10 Banka',
            left: 'center',
            textStyle: {
                color: '#acb5bd',
                fontSize: 18,
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            },
            subtextStyle: {
                color: '#acb5bd',
                fontSize: 14,
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' }
        },
        xAxis: {
            type: 'category',
            data: data.map(item => item.name),
            axisLabel: {
                interval: 0,
                rotate: 45,
                color: '#acb5bd',
                fontSize: 10,
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#acb5bd',
                fontSize: 12,
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            }
        },
        series: [
            {
                name: 'Bakiye',
                type: 'bar',
                barWidth: '60%',
                data: data.map(item => ({
                    value: item.value,
                    itemStyle: { color: getRandomColor(), borderRadius: [10, 10, 0, 0] }
                }))
            }
        ]
    };

    return (
        <React.Fragment>
            <ReactEcharts
                option={option}
                style={{ height: '100%', width: '100%' }}
            />
        </React.Fragment>
    );
}

export default OnlineBankaBakiyeChart;
