import React, { useEffect, useState } from "react";
import { Row, Col, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerUser, apiError, resetRegisterFlag } from "../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import AuthSlider from "../AuthenticationInner/authCarousel";

const Register = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            first_name: '',
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Lütfen e-posta adresinizi girin"),
            first_name: Yup.string().required("Lütfen kullanıcı adınızı girin"),
            password: Yup.string()
                .min(8, 'Şifre en az 8 karakter olmalı')
                .matches(RegExp('(.*[a-z].*)'), 'En az bir küçük harf içermeli')
                .matches(RegExp('(.*[A-Z].*)'), 'En az bir büyük harf içermeli')
                .matches(RegExp('(.*[0-9].*)'), 'En az bir rakam içermeli')
                .required("Lütfen şifrenizi girin"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("password")], "Şifreler eşleşmiyor")
                .required("Lütfen şifrenizi onaylayın"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    const selectLayoutState = (state) => state.Account;
    const registerdatatype = createSelector(
        selectLayoutState,
        (account) => ({
            success: account.success,
            error: account.error
        })
    );

    const { error, success } = useSelector(registerdatatype);

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history("/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);
    }, [dispatch, success, error, history]);

    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    document.title = "Basic SignUp | Velzon - React Admin & Dashboard Template";


    console.log(validation.touched.confirm_password ? 30 : 0 )
    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden m-0">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">Hesap Oluştur</h5>
                                                </div>

                                                <div className="mt-4">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        className="needs-validation" action="#">

                                                        {success && success ? (
                                                            <>
                                                                {toast("Giriş Sayfasına Yönlendiriliyorsunuz...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                                <ToastContainer autoClose={2000} limit={1} />
                                                                <Alert color="success">
                                                                    Kullanıcı Başarıyla Kaydedildi ve Giriş Sayfasına Yönlendiriliyorsunuz...
                                                                </Alert>
                                                            </>
                                                        ) : null}

                                                        {error && error ? (
                                                            <Alert color="danger"><div>
                                                                Bu e-posta zaten kayıtlı, lütfen başka bir e-posta adresi kullanın...</div></Alert>
                                                        ) : null}

                                                        <div className="mb-3">
                                                            <label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="email"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="E-posta adresinizi girin"
                                                                type="email"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false
                                                                }
                                                            />
                                                            {validation.touched.email && validation.errors.email ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="username" className="form-label">Kullanıcı Adı <span className="text-danger">*</span></label>
                                                            <Input
                                                                name="first_name"
                                                                type="text"
                                                                placeholder="Kullanıcı adınızı girin"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.first_name || ""}
                                                                invalid={
                                                                    validation.touched.first_name && validation.errors.first_name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.first_name && validation.errors.first_name ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.first_name}</div></FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="password-input">Şifre <span className="text-danger">*</span></label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    name="password"
                                                                    type={passwordShow ? "text" : "password"}
                                                                    placeholder="Şifrenizi girin"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password || ""}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                    className="custom-input"
                                                                />
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                                ) : null}

                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                                    <i className="ri-eye-fill align-middle"></i>
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="confirmPassword" className="form-label">Şifreyi Onaylayın <span className="text-danger">*</span></Label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    name="confirm_password"
                                                                    type={confirmPasswordShow ? "text" : "password"}
                                                                    placeholder="Şifrenizi onaylayın"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.confirm_password || ""}
                                                                    invalid={
                                                                        validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                                    }
                                                                    className="custom-input"
                                                                />
                                                                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                    <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                                ) : null}

                                                                <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon">
                                                                    <i className="ri-eye-fill align-middle"></i>
                                                                </Button>
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <p className="mb-0 fs-12 text-muted fst-italic">Kayıt olarak Velzon'un <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Kullanım Koşullarını</Link> kabul etmiş olursunuz.</p>
                                                        </div>

                                                        <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                            <h5 className="fs-13">Şifre aşağıdakileri içermelidir:</h5>
                                                            <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 karakter</b></p>
                                                            <p id="pass-lower" className="invalid fs-12 mb-2">En az bir <b>küçük harf</b> (a-z)</p>
                                                            <p id="pass-upper" className="invalid fs-12 mb-2">En az bir <b>büyük harf</b> (A-Z)</p>
                                                            <p id="pass-number" className="invalid fs-12 mb-0">En az bir <b>rakam</b> (0-9)</p>
                                                        </div>

                                                        <div className="mt-4">
                                                            <button className="btn btn-success w-100" type="submit">Kayıt Ol</button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <div className="signin-other-title">
                                                                <h5 className="fs-13 mb-4 title text-muted">Hesap oluşturun</h5>
                                                            </div>

                                                            <div>
                                                                <button type="button" className="btn btn-primary btn-icon waves-effect waves-light me-1"><i className="ri-facebook-fill fs-16"></i></button>
                                                                <button type="button" className="btn btn-danger btn-icon waves-effect waves-light me-1"><i className="ri-google-fill fs-16"></i></button>
                                                                <button type="button" className="btn btn-dark btn-icon waves-effect waves-light me-1"><i className="ri-github-fill fs-16"></i></button>
                                                                <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">Zaten bir hesabınız var mı? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Giriş Yapın</Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default Register;
