import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardBody, Col, Row } from 'reactstrap';

const data = {
    labels: ['15 - Nisan', '16 - Nisan', '17 - Nisan', '18 - Nisan', '19 - Nisan'],
    datasets: [
        {
            label: 'Tahsilat Raporu',
            data: [25000000, 30000000, 0],
            backgroundColor: [
                'rgba(133, 197, 227, 0.85)', // İlk bar
                'rgba(132, 169, 227, 0.85)', // İkinci bar
                'rgba(133, 197, 227, 0.85)', // Üçüncü bar
                'rgba(132, 169, 227, 0.85)', // Dördüncü bar
                'rgba(133, 197, 227, 0.85)', // Beşinci bar
            ],
            borderColor: [
                'rgba(133, 197, 227, 1)', // İlk bar
                'rgba(132, 169, 227, 1)', // İkinci bar
                'rgba(133, 197, 227, 1)', // Üçüncü bar
                'rgba(132, 169, 227, 1)', // Dördüncü bar
                'rgba(133, 197, 227, 1)', // Beşinci bar
            ],
            borderWidth: 1,
            borderRadius: 10, // Barların köşe yarıçapı
            barPercentage: 1, // Bar genişliğini ayarlamak için
            hoverBackgroundColor: [
                'rgba(133, 197, 227, 0.75)', // İlk bar
                'rgba(132, 169, 227, 0.75)', // İkinci bar
                'rgba(133, 197, 227, 0.75)', // Üçüncü bar
                'rgba(132, 169, 227, 0.75)', // Dördüncü bar
                'rgba(133, 197, 227, 0.75)', // Beşinci bar
            ],
            hoverBorderColor: [
                'rgba(133, 197, 227, 0.75)', // İlk bar
                'rgba(132, 169, 227, 0.75)', // İkinci bar
                'rgba(133, 197, 227, 0.75)', // Üçüncü bar
                'rgba(132, 169, 227, 0.75)', // Dördüncü bar
                'rgba(133, 197, 227, 0.75)', // Beşinci bar
            ]
        }
    ]
};

const options = {
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                callback: function(value) {
                    return value.toLocaleString(); // Y eksenindeki değerleri virgülle ayırarak formatlar
                },
                font: {
                    size: 14,
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                color: '#acb5bd'
            },
            grid: {
                color: '#acb5bd',
                borderDash: [2, 2]
            }
        },
        x: {
            ticks: {
                font: {
                    size: 14,
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                color: '#acb5bd'
            },
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            display: false // Grafik başlığını gizler
        },
        tooltip: {
            callbacks: {
                label: function(tooltipItem) {
                    return tooltipItem.raw.toLocaleString(); // Tooltip içinde virgülle ayrılmış sayılar gösterir
                }
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false
};

const TahsilatRaporuChart = () => {
    return (
        <React.Fragment>
            <Bar data={data} options={options} />
        </React.Fragment>
    );
};

export default TahsilatRaporuChart;
