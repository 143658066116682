import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container, Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const CevirimTipiData = [
  { value: "cevirimTipi1", label: "Çevirim Tipi 1" },
  { value: "cevirimTipi2", label: "Çevirim Tipi 2" },
];
const BakiyeTipiData = [
  { value: "bakiyeTipi1", label: "Bakiye Tipi 1" },
  { value: "bakiyeTipi2", label: "Bakiye Tipi 2" },
];
const HedefParaBirimiData = [
  { value: "try", label: "TÜRK LİRASI" },
  { value: "usd", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];
const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];
const RaporTipiData = [
  { value: "raporTipi1", label: "Rapor Tipi 1" },
  { value: "raporTipi2", label: "Rapor Tipi 2" },
];
const HesapTuruData = [
  { value: "vadeli", label: "VADELİ" },
  { value: "vadesiz", label: "VADESİZ" },
];

const BirBakistaDovizCevirici = () => {
  const { t } = useTranslation();
  document.title = t("Bir Bakışta Döviz Çevirici");

  const [banka, setBanka] = useState(null);
  const [firma, setFirma] = useState(null);
  const [cevirimTipi, setCevirimTipi] = useState(null);
  const [bakiyeTipi, setBakiyeTipi] = useState(null);
  const [tarih, setTarih] = useState("");
  const [hedefParaBirimi, setHedefParaBirimi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [raporTipi, setRaporTipi] = useState(null);
  const [hesapTuru, setHesapTuru] = useState(null);
  const [records, setRecords] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function cevirimTipiIslem(selectedSingle) {
    setCevirimTipi(selectedSingle);
  }
  function bakiyeTipiIslem(selectedSingle) {
    setBakiyeTipi(selectedSingle);
  }
  const tarihiIslem = (e) => {
    setTarih(e.target.value);
  };
  function hedefParaBirimiIslem(selectedSingle) {
    setHedefParaBirimi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }
  function raporTipiIslem(selectedSingle) {
    setRaporTipi(selectedSingle);
  }
  function hesapTuruIslem(selectedSingle) {
    setHesapTuru(selectedSingle);
  }

  const EkleIslem = async () => {
    if (!banka || !firma || !cevirimTipi || !bakiyeTipi || !tarih || !hedefParaBirimi || !durum || !raporTipi || !hesapTuru) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniKayit = {
      banka: banka.label,
      firma: firma.label,
      cevirimTipi: cevirimTipi.label,
      bakiyeTipi: bakiyeTipi.label,
      tarih,
      hedefParaBirimi: hedefParaBirimi.label,
      durum: durum.label,
      raporTipi: raporTipi.label,
      hesapTuru: hesapTuru.label,
    };

    let apiVerisi = [
      {
        firma: "ASSISTCAM ASISTANS",
        paraBirimi: "TÜRK LİRASI",
        toplamBakiye: 4997.22,
        kurTipi: "SATIŞ KURU",
        kur: 32.5242,
        hedefParaBirimi: 153.65,
      },
      {
        firma: "DİGİLERA BİLGİ TEKNOLOJİLERİ",
        paraBirimi: "TÜRK LİRASI",
        toplamBakiye: 112988.75,
        kurTipi: "SATIŞ KURU",
        kur: 32.5242,
        hedefParaBirimi: 3473.99,
      },
      {
        firma: "OPTİMUM OTOMOTİV",
        paraBirimi: "TÜRK LİRASI",
        toplamBakiye: 1617682.47,
        kurTipi: "SATIŞ KURU",
        kur: 32.5242,
        hedefParaBirimi: 49737.81,
      },
      {
        firma: "",
        paraBirimi: "DOLAR",
        toplamBakiye: 58.94,
        kurTipi: "ÇAPRAZ KUR",
        kur: 1.0000,
        hedefParaBirimi: 58.94,
      },
      {
        firma: "",
        paraBirimi: "EURO",
        toplamBakiye: 0.00,
        kurTipi: "ÇAPRAZ KUR",
        kur: 1.0659,
        hedefParaBirimi: 0.00,
      },
    ]

    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/bir-bakista-doviz-cevirici', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const calculateTotal = (records, field, isCurrency = false) => {
    if (!Array.isArray(records) || records.length === 0) return isCurrency ? '0,00' : '0';
    const total = records.reduce((acc, record) => {
      const value = isCurrency ? parseFloat(record[field] || 0) : parseInt(record[field] || 0, 10);
      return acc + value;
    }, 0);
    return isCurrency ? total.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : total.toLocaleString('tr-TR');
  };
  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Bir Bakışta Döviz Çevirici")} pageTitle={t("Bir Bakışta")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* veri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Çevirim Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="cevirimTipi" className="form-label text-capitalize">
                              Çevirim Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={cevirimTipi}
                                onChange={cevirimTipiIslem}
                                options={CevirimTipiData}
                            />
                          </div>

                          {/* Bakiye Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="bakiyeTipi" className="form-label text-capitalize">
                              Bakiye Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={bakiyeTipi}
                                onChange={bakiyeTipiIslem}
                                options={BakiyeTipiData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="tarihi"
                                    value={tarih}
                                    onChange={tarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Hedef Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="hedefParaBirimi" className="form-label text-capitalize">
                              Hedef Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hedefParaBirimi}
                                onChange={hedefParaBirimiIslem}
                                options={HedefParaBirimiData}
                            />
                          </div>

                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="durum" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>

                          {/* Rapor Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="raporTipi" className="form-label text-capitalize">
                              Rapor Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={raporTipi}
                                onChange={raporTipiIslem}
                                options={RaporTipiData}
                            />
                          </div>

                          {/* Hesap Türü select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapTuru" className="form-label text-capitalize">
                              Hesap Türü
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapTuru}
                                onChange={hesapTuruIslem}
                                options={HesapTuruData}
                            />
                          </div>
                        </Col>

                        {/* çevir buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Çevir
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Para Birimi</th>
                            <th className="text-capitalize">Toplam Bakiye</th>
                            <th className="text-capitalize">Kur Tipi</th>
                            <th className="text-capitalize">Kur</th>
                            <th className="text-capitalize">{hedefParaBirimi ? hedefParaBirimi.label : "Hedef Para Birimi"}</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.firma}</td>
                                      <td className="text-uppercase">{records.paraBirimi}</td>
                                      <td>{formatCurrency(records.toplamBakiye)}</td>
                                      <td className="text-uppercase">{records.kurTipi}</td>
                                      <td>{formatCurrency(records.kur, 4)}</td>
                                      <td>{formatCurrency(records.hedefParaBirimi)}</td>
                                    </tr>
                                );
                              })}


                              {/* Toplam Satırı */}
                              <tr className="table-light">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-uppercase" style={{ fontWeight: "bold" }}>TOPLAM</td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records, 'hedefParaBirimi', true)}
                                </td>
                              </tr>

                              {/* Toplam Satırı */}
                              <tr className="table-light">
                                <td className="text-uppercase" style={{ fontWeight: "bold" }}>TOPLAM</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records, 'toplamBakiye', true)}
                                </td>
                              </tr>

                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default BirBakistaDovizCevirici;
