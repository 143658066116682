import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const HesapTuruData = [
  { value: "vadeli", label: "VADELİ" },
  { value: "vadesiz", label: "VADESİZ" },
];
const ParaBirimiData = [
  { value: "tl", label: "TL" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];
const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];

const PosDonusluGunSonuBakiye = () => {
  const { t } = useTranslation();
  document.title = t("Pos Dönüşlü Gün Sonu Bakiye");

  const [banka, setBanka] = useState(null);
  const [firma, setFirma] = useState(null);
  const [sube, setSube] = useState(null);
  const [hesapAdi, setHesapAdi] = useState(null);
  const [tarih, setTarih] = useState("");
  const [hesapTuru, setHesapTuru] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const [records, setRecords] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function hesapTuruIslem(selectedSingle) {
    setHesapTuru(selectedSingle);
  }
  const tarihIslem = (e) => {
    setTarih(e.target.value);
  };
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!banka || !firma || !sube || !hesapTuru || !paraBirimi || !durum) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }

    const yeniKayit = {
      banka: banka.label,
      firma: firma.label,
      sube,
      hesapTuru: hesapTuru.label,
      paraBirimi: paraBirimi.label,
      durum: durum.label,
    };

    const apiVerisi = [
      {
        paraBirimi: "TURK LİRASI",
        toplamBakiye: 646906.94,
        toplamBlokeBakiye: 0.00
      },
    ]

    setRecords([...records, ...apiVerisi]);

    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/capraz-virman', yeniKayit);
    //   if (response.data.success) {
    //       setTable1([...table1, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const toggleModal = (status, data) => {
    setModal(status);
    setModalData(data);
  };

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Pos Dönüşlü Gün Sonu Bakiye")} pageTitle={t("Özet Raporlar")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* listeleme input kısmı */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*durum, tarih saat ve bitiş*/}
                      <Row className="mt-1 mb-3">
                        <Col xs={12}>
                          <Row>
                            <Col xs={12} md={8} className="d-flex align-items-center">
                              <div className="d-flex">
                                <div className="d-flex py-2 px-3 me-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                                  <div className="me-2 text-uppercase" style={{fontWeight: 500}}>
                                    TARİH - BİTİŞ:
                                  </div>
                                  <div className="text-danger" style={{fontWeight: 500}}>
                                    {tarih ? formatDateString(tarih) : "-"}
                                  </div>
                                </div>

                                <div className="d-flex py-2 px-3 ms-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                                  <i className={`mdi me-2 ${durum?.value === "aktif" ? "mdi-check-bold text-success" : durum?.value === "pasif" ? "mdi-close-thick text-danger" : ""}`}></i>

                                  <div className="me-2 text-uppercase" style={{fontWeight: 500}}>
                                    DURUM:
                                  </div>
                                  <div className="text-danger" style={{fontWeight: 500}}>
                                    {durum ? durum.label : '-'}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={4} className="d-flex justify-content-start justify-content-md-end mt-3 mb-md-0">
                              <button className="btn btn-primary p-0 m-0" style={{width: 40, height: 40}}  onClick={() => toggleModal(true, "FİLTRELE")}>
                                <i className="ri-filter-2-fill" style={{ fontSize: '24px' }}></i>
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* verileri ekleme */}
                      <Row>
                        {/*inputlar, selectler ve listele buton*/}
                        <Col lg={6}>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Şube input */}
                          <div className="mb-3">
                            <Label htmlFor="sube" className="form-label text-capitalize">
                              Şube
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="sube"
                                placeholder="Şube"
                                value={sube}
                                onChange={(e) => setSube(e.target.value)}
                            />
                          </div>

                          {/* Hesap Türü select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapTuru" className="form-label text-capitalize">
                              Hesap Türü
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapTuru}
                                onChange={hesapTuruIslem}
                                options={HesapTuruData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="tarih"
                                    value={tarih}
                                    onChange={tarihIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>

                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="durum" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              {/* listeleme kısmı */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th style={{width: 55}}></th>
                            <th className="text-capitalize">Para Birimi</th>
                            <th className="text-capitalize">Toplam Bakiye</th>
                            <th className="text-capitalize">Toplam Bloke Bakiye</th>
                          </tr>
                          </thead>

                          {displayedRecords?.length >= 1 && (
                              <tbody>
                              {displayedRecords?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="d-flex justify-content-center align-items-center">
                                        <i className="bx bx-plus" style={{fontSize: "20px"}}></i>
                                      </td>
                                      <td className="text-uppercase">{records.paraBirimi}</td>
                                      <td>{formatCurrency(records.toplamBakiye)}</td>
                                      <td>{formatCurrency(records.toplamBlokeBakiye)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Col>
          </Container>
        </div>

        <Modal isOpen={modal} toggle={() => toggleModal(false, "")}>
          <ModalHeader toggle={() => toggleModal(false, "")}>{modalData}</ModalHeader>
          <ModalBody>
            {/* Modal içeriği buraya gelecek */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal(false, "")}>Kapat</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default PosDonusluGunSonuBakiye;
