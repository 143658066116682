import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const ParaBirimiData = [
  { value: "tl", label: "TL" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];
const HareketDurumuData = [
  { value: "hareketDurumu1", label: "Hareket Durumu 1" },
  { value: "hareketDurumu2", label: "Hareket Durumu 2" },
];

const HesapHareketRaporu = () => {
  const { t } = useTranslation();
  document.title = t("Hesap Hareket Raporu");

  const [banka, setBanka] = useState(null);
  const [firma, setFirma] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [hareketDurumu, setHareketDurumu] = useState(null);
  const [records, setRecords] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function hareketDurumuIslem(selectedSingle) {
    setHareketDurumu(selectedSingle);
  }

  const EkleIslem = async () => {
    if (!banka || !firma || !paraBirimi || !hareketDurumu) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniKayit = {
      banka: banka.label,
      firma: firma.label,
      paraBirimi: paraBirimi.label,
      hareketDurumu: hareketDurumu.label,
    };

    let apiVerisi = [
      {
        banka: "Türkiye İş Bankası",
        firma: "OPTİMUM OTOMOTİV",
        sube: "DUDULLU TİCARİ",
        iban: "TR11111111111",
        hesapTuru: "VADESİZ",
        bakiye: 946.16,
        pb: "TRY",
        sonHareketTarihi: "01.04.2024",
        hareketsizGunSayisi: 16,
      },
      {
        banka: "VakıfBank",
        firma: "OPTİMUM OTOMOTİV",
        sube: "GEBZE ORGANİZE",
        iban: "TR11111111111",
        hesapTuru: "VADESİZ",
        bakiye: 0.00,
        pb: "USD",
        sonHareketTarihi: "24.10.2023",
        hareketsizGunSayisi: 176,
      },
    ]

    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/bir-bakista-online-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Hesap Hareket Raporu")} pageTitle={t("Diğer Raporlar")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* veri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>

                          {/* Hareket Durumu select */}
                          <div className="mb-3">
                            <Label htmlFor="hareketDurumu" className="form-label text-capitalize">
                              Hareket Durumu
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hareketDurumu}
                                onChange={hareketDurumuIslem}
                                options={HareketDurumuData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Şube</th>
                            <th className="text-uppercase">IBAN</th>
                            <th className="text-capitalize">Hesap Türü</th>
                            <th className="text-capitalize">Bakiye</th>
                            <th className="text-uppercase">P.B.</th>
                            <th className="text-capitalize">Son Hareket Tarihi</th>
                            <th className="text-capitalize">Hareketsiz Gün Sayısı</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.banka}</td>
                                      <td className="text-uppercase">{records.firma}</td>
                                      <td className="text-uppercase">{records.sube}</td>
                                      <td className="text-uppercase">{records.iban}</td>
                                      <td className="text-uppercase">{records.hesapTuru}</td>
                                      <td>{formatCurrency(records.bakiye)}</td>
                                      <td className="text-uppercase">{records.pb}</td>
                                      <td>{records.sonHareketTarihi}</td>
                                      <td>{records.hareketsizGunSayisi}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default HesapHareketRaporu;
