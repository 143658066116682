import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";

const initialData = [
    { value: 45.2, name: 'Ziraat Bankası' },
    { value: 31.5, name: 'Garanti BBVA' },
    { value: 8.6, name: 'Vakıfbank' },
    { value: 8.5, name: 'Yapı Kredi' },
    { value: 5.3, name: 'TEB' },
    { value: 0.4, name: 'Akbank' },
    { value: 0.3, name: 'KuveytTürk' },
    { value: 0.0, name: 'İş Bankası' }
];

const colorPalette = ['#66b7dc', '#6794db', '#6771dc', '#8067dc', '#a267db', '#c667dc', '#db67ce', '#db67ab'];

const BankaTahsilatRaporuChart = () => {
    const [data, setData] = useState(initialData);

    const legendData = data.map(item => item.name);
    let seriesData = data.map((item, index) => ({
        value: item.value,
        name: item.name,
        itemStyle: { color: colorPalette[index % colorPalette.length], borderRadius: 10 }
    }));

    const option = {
        title: {
            text: 'BANKA TAHSİLAT RAPORU Nisan 2024',
            left: 'center',
            top: '2%',
            textStyle: {
                color: '#acb5bd',
                fontSize: 18,
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
            bottom: '2%',
            left: 'center',
            data: legendData,
            textStyle: {
                color: '#acb5bd',
                fontSize: 14,
                fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            }
        },
        series: [
            {
                name: 'Banka Tahsilat',
                type: 'pie',
                radius: ['50%', '80%'],
                center: ['48%', '70%'],
                avoidLabelOverlap: false,
                startAngle: 180,
                endAngle: 0,
                label: {
                    show: true,
                    position: 'outside',
                    formatter: '{b}: {d}%',
                    color: '#acb5bd',
                    fontSize: 9,
                    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                labelLine: {
                    show: true
                },
                data: seriesData
            }
        ],
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
    };

    const onChartLegendSelectChanged = (params, echartsInstance) => {
        const selected = params.selected;
        const selectedValues = Object.values(selected);
        const allUnselected = selectedValues.every(value => !value);

        if (allUnselected) {
            echartsInstance.setOption({
                series: [{
                    avoidLabelOverlap: false,
                    type: 'pie',
                    radius: ['0%', '0%'],
                    center: ['48%', '70%'],
                    startAngle: 180,
                    endAngle: 0,
                }]
            });
        } else {
            echartsInstance.setOption({
                series: [{
                        name: 'Banka Tahsilat',
                        type: 'pie',
                        radius: ['50%', '80%'],
                        center: ['48%', '70%'],
                        avoidLabelOverlap: false,
                        startAngle: 180,
                        endAngle: 0,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: '{b}: {d}%',
                            color: '#acb5bd',
                            fontSize: 9,
                            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                        },
                        labelLine: {
                            show: true
                        },

                    data: initialData.map((item, index) => ({
                        value: selected[item.name] ? item.value : 0,
                        name: item.name,
                        itemStyle: { color: colorPalette[index % colorPalette.length], borderRadius: 10 }
                    }))
                }],
                legend: {
                    data: legendData,
                    selected: params.selected // Keep the legend selections
                }
            });
        }
    };

    return (
        <React.Fragment>
            <ReactEcharts
                option={option}
                style={{ height: '400px', width: '100%' }}
                onEvents={{
                    'legendselectchanged': onChartLegendSelectChanged
                }}
            />
        </React.Fragment>
    );
}

export default BankaTahsilatRaporuChart;
