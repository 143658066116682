import React from "react";
import ReactEcharts from "echarts-for-react";

import getChartColorsArray from "../../../../../Components/Common/ChartsDynamicColor";


//AreaChart
const SaatlikRaporChart = ({ dataColors, apiVerisi }) => {

    var chartAreaColors = getChartColorsArray(dataColors);
    var option = {
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '4%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                let result = `<b>Saat: ${params[0].name}</b><br/>`;
                params.forEach(item => {
                    let formattedValue = new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(item.value);
                    result += `${item.seriesName}: ${formattedValue}<br/>`;
                });
                return result;
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: '#ccc',
            borderWidth: 1,
            textStyle: {
                color: '#333'
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data:  apiVerisi.map(item => item.saat),
            axisLine: {
                lineStyle: {
                    color: '#acb5bd'
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#acb5bd'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        series: [{
            name: 'Değer',
            data: apiVerisi.map(item => item.toplamTutar),
            type: 'line',
            smooth: true,
            areaStyle: {
                color: 'rgba(140, 85, 149, 0.5)'
            },
            lineStyle: {
                color: 'rgba(140, 85, 149, 1)'
            },
            itemStyle: {
                color: '#ffffff',
                borderColor: 'rgba(140, 85, 149, 1)',
                borderWidth: 2
            },
            symbol: 'circle',
            symbolSize: 8,
        }],

        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: chartAreaColors[0],
        backgroundColor: chartAreaColors[1],
    };

    return (
        <React.Fragment>
            <ReactEcharts option={option} />
        </React.Fragment>
    )
}

export default SaatlikRaporChart;



