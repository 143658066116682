import React, {useState} from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import Section from "../../pages/DashboardEcommerce/Section";
import AlacakChart from "./Charts/AlacakChart";
import BorcChart from "./Charts/BorcChart";
import ToplamChart from "./Charts/ToplamChart";
import GunSonuBakiyeChart from "./Charts/GunSonuBakiyeChart";
import CountUp from "react-countup";
import Select from "react-select";
import TahsilatRaporuChart from "./Charts/TahsilatRaporuChart";
import OdemeRaporuChart from "./Charts/OdemeRaporuChart";
import BankaTahsilatRaporuChart from "./Charts/BankaTahsilatRaporuChart";
import OnlineBankaBakiyeChart from "./Charts/OnlineBankaBakiyeChart";

const Data1 = [
    {value: "Data11", label: "Data11"},
    {value: "Data12", label: "Data12"},
];
const Data2 = [
    {value: "Data21", label: "Data21"},
    {value: "Data22", label: "Data22"},
];
const Data3 = [
    {value: "Data31", label: "Data31"},
    {value: "Data32", label: "Data32"},
];


const chartData = {
    id: 1,
    alacakTutar: "408305.85",
    borcTutar: "350632.51",
    alacakAdet: "17",
    borcAdet: "3"
}

// Alacak ve borç değerlerini sayıya çevirip çıkarma işlemi
const toplamTutar = parseFloat(chartData.alacakTutar) - parseFloat(chartData.borcTutar);
const toplamAdet = parseFloat(chartData.alacakAdet) + parseFloat(chartData.borcAdet);
const DashboardScreen = () => {
    document.title = "Dashboard";
    const [data1Select, setData1Select] = useState(null);
    const [data2Select, setData2Select] = useState(null);
    const [data3Select, setData3Select] = useState(null);

    const [manuelBakiyeSelect, setManuelBakiyeSelect] = useState(null);
    const [gunSonuBakiyeSelect, setGunSonuBakiyeSelect] = useState(null);

    function data1Islem(selectedSingle) {
        setData1Select(selectedSingle);
    }
    function data2Islem(selectedSingle) {
        setData2Select(selectedSingle);
    }
    function data3Islem(selectedSingle) {
        setData3Select(selectedSingle);
    }
    function manuelBakiyeIslem(selectedSingle) {
        setManuelBakiyeSelect(selectedSingle);
    }
    function gunSonuBakiyeIslem(selectedSingle) {
        setGunSonuBakiyeSelect(selectedSingle);
    }


    const customStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '14px',
            borderRadius: '45px',
            borderColor: '#9ec9e7',
        })
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="h-100">
                                <Section Data1={Data1} Data2={Data2} Data3={Data3}
                                         data1Islem={data1Islem} data2Islem={data2Islem} data3Islem={data3Islem}
                                         data1Select={data1Select} data2Select={data2Select} data3Select={data3Select}
                                />
                                <Row>
                                    <Col xs={12} lg={4}>
                                        <AlacakChart chartData={chartData}/>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <BorcChart chartData={chartData}/>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <ToplamChart toplamTutar={toplamTutar} toplamAdet={toplamAdet}/>
                                    </Col>
                                </Row>

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} lg={4}>
                                            <Card style={{height: 430}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-3" style={{minHeight: 50}}>
                                                    <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                        Online Bakiye
                                                    </h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row className="d-flex align-items-center">
                                                        <Col xs={6}>
                                                            <i className="mdi mdi-bank la-6x"
                                                               style={{color: '#b2b7ed'}}></i>
                                                        </Col>
                                                        <Col xs={6} className="d-flex flex-column text-end">
                                                            <span className="fs-11 text-capitalize">Online Bakiye</span>
                                                            <span className="fs-23" style={{color: "#fe6c0e"}}>
                                                                <CountUp
                                                                    start={0}
                                                                    end={646906.94}
                                                                    decimals={2}
                                                                    separator=","
                                                                    duration={4}
                                                                    formattingFn={(value) => value.toLocaleString()}
                                                                />
                                                            </span>
                                                            <span className="fs-11 text-capitalize">Bloke Bakiye</span>
                                                            <span className="fs-23" style={{color: "#ff607b"}}>
                                                                <CountUp
                                                                    start={0}
                                                                    end={0.0}
                                                                    decimals={2}
                                                                    separator=","
                                                                    duration={4}
                                                                    formattingFn={(value) => value.toLocaleString()}
                                                                />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Col xs={12} className="mt-5">
                                                        <div
                                                            className="d-flex flex-row justify-content-between mb-3 pb-2 border-bottom"
                                                            style={{color: "#acb5bd"}}>
                                                            <span className="text-capitalize">Firma</span>
                                                            <span>3</span>
                                                        </div>

                                                        <div
                                                            className="d-flex flex-row justify-content-between mb-3 pb-2 border-bottom"
                                                            style={{color: "#acb5bd"}}>
                                                            <span className="text-capitalize">Banka</span>
                                                            <span>8</span>
                                                        </div>

                                                        <div
                                                            className="d-flex flex-row justify-content-between mb-3 pb-2 border-bottom"
                                                            style={{color: "#acb5bd"}}>
                                                            <span className="text-capitalize">Hesap</span>
                                                            <span>13</span>
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12} lg={4}>
                                            <Card style={{height: 430}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{minHeight: 50}}>
                                                        <Col xs={7}>
                                                            <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                                Manuel Bakiye
                                                            </h6>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <Select
                                                                value={manuelBakiyeSelect}
                                                                onChange={manuelBakiyeIslem}
                                                                options={Data3}
                                                                styles={customStyles}
                                                            />
                                                        </Col>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row className="d-flex align-items-center">
                                                        <Col xs={6}>
                                                            <i className="mdi mdi-database la-6x"
                                                               style={{color: '#ffdf82'}}></i>
                                                        </Col>
                                                        <Col xs={6} className="d-flex flex-column text-end">
                                                            <span className="fs-11 text-capitalize">Manuel Bakiye</span>
                                                            <span className="fs-23" style={{color: "#fe6c0e"}}>
                                                                <CountUp
                                                                    start={0}
                                                                    end={0.0}
                                                                    decimals={2}
                                                                    separator=","
                                                                    duration={4}
                                                                    formattingFn={(value) => value.toLocaleString()}
                                                                />
                                                            </span>
                                                            <span className="fs-11 text-capitalize">Manuel Bloke Bakiye</span>
                                                            <span className="fs-23" style={{color: "#ff607b"}}>
                                                                <CountUp
                                                                    start={0}
                                                                    end={0.0}
                                                                    decimals={2}
                                                                    separator=","
                                                                    duration={4}
                                                                    formattingFn={(value) => value.toLocaleString()}
                                                                />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Col xs={12} className="mt-5">
                                                        <div
                                                            className="d-flex flex-row justify-content-between mb-3 pb-2 border-bottom"
                                                            style={{color: "#acb5bd"}}>
                                                            <span className="text-capitalize">Firma</span>
                                                            <span>0</span>
                                                        </div>

                                                        <div
                                                            className="d-flex flex-row justify-content-between mb-3 pb-2 border-bottom"
                                                            style={{color: "#acb5bd"}}>
                                                            <span className="text-capitalize">Banka</span>
                                                            <span>0</span>
                                                        </div>

                                                        <div
                                                            className="d-flex flex-row justify-content-between mb-3 pb-2 border-bottom"
                                                            style={{color: "#acb5bd"}}>
                                                            <span className="text-capitalize">Hesap</span>
                                                            <span>0</span>
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12} lg={4}>
                                            <Card style={{height: 430}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{minHeight: 50}}>
                                                    <Col xs={7}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                            Gün Sonu Bakiye
                                                        </h6>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <Select
                                                            value={gunSonuBakiyeSelect}
                                                            onChange={gunSonuBakiyeIslem}
                                                            options={Data3}
                                                            styles={customStyles}
                                                        />
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <GunSonuBakiyeChart dataColors='["--vz-success"]'/>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Card style={{height: 430}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{minHeight: 50}}>
                                                    <Col xs={7}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                           Tahsilat Raporu
                                                        </h6>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <Select
                                                            value={gunSonuBakiyeSelect}
                                                            onChange={gunSonuBakiyeIslem}
                                                            options={Data3}
                                                            styles={customStyles}
                                                        />
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <TahsilatRaporuChart dataColors='["--vz-success"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Card style={{height: 430}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{minHeight: 50}}>
                                                    <Col xs={7}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                            Ödeme Raporu
                                                        </h6>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <Select
                                                            value={gunSonuBakiyeSelect}
                                                            onChange={gunSonuBakiyeIslem}
                                                            options={Data3}
                                                            styles={customStyles}
                                                        />
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <OdemeRaporuChart dataColors='["--vz-success"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Card style={{height: 530}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{minHeight: 50}}>
                                                    <Col xs={7}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                            Banka Tahsilat Raporu
                                                        </h6>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <Select
                                                            value={gunSonuBakiyeSelect}
                                                            onChange={gunSonuBakiyeIslem}
                                                            options={Data3}
                                                            styles={customStyles}
                                                        />
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <BankaTahsilatRaporuChart />
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Card style={{height: 530}}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-3" style={{minHeight: 50}}>
                                                    <Col xs={12}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{color: "#7ba6cb"}}>
                                                            Online Banka Bakiye
                                                        </h6>
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <OnlineBankaBakiyeChart />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardScreen;
