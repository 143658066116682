import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const Kategori = () => {
  const { t } = useTranslation();
  document.title = t("Kategori");
  const [kategori, setKategori] = useState("");
  const [records, setRecords] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!kategori) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniKayit = [
      {
       kategori,
      }
    ]
    setRecords([...records, ...yeniKayit]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/capraz-virman', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  // geçici buton
  const handleDelete = (index) => {
    const newRecords = records.filter((_, i) => i !== index);
    setRecords(newRecords);
    durationnotify("Kayıt başarıyla silindi.", 'info');
  };
  // const handleDelete = async (index) => {
  //   const itemToDelete = records[index];
  //   try {
  //     const response = await axios.delete(`/api/capraz-virman-delete/${itemToDelete.id}`);
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, i) => i !== index);
  //         setRecords(newRecords);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };


  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = displayedRecords.map((_, index) => (currentPage - 1) * itemsPerPage + index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };


  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // geçici buton
  const handleBulkDelete = () => {
    const newRecords = records.filter((_, index) => !selectedItems.includes(index));
    setRecords(newRecords);
    setSelectedItems([]);
    durationnotify("Seçilen kayıtlar başarıyla silindi.", 'info');
  };
  // const handleBulkDelete = async () => {
  //   const itemsToDelete = selectedItems.map(index => hareketler[index]);
  //   try {
  //     const response = await axios.post('/api/hareketler/bulk-delete', { items: itemsToDelete });
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, index) => !selectedItems.includes(index));
  //       setHareketler(newHareketler);
  //       setSelectedItems([]);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Kategori")} pageTitle={t("Nakit Yönetimi")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Kategori input */}
                          <div className="mb-3">
                            <Label htmlFor="kategori" className="form-label text-capitalize">
                              Kategori
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="kategori"
                                placeholder="Kategori"
                                value={kategori}
                                onChange={(e) => setKategori(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>

                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Kategori Ekle
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3 text-capitalize">
                          <Button color="danger" onClick={handleBulkDelete} disabled={selectedItems.length === 0}>
                            Toplu Kategori Sil
                          </Button>
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}>
                              <div className="form-check">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                    onChange={handleSelectAll}
                                    checked={selectedItems.length === displayedRecords.length}
                                />
                              </div>
                            </th>
                            <th className="text-capitalize">Kategori</th>
                            <th className="text-capitalize">Kategori Sil</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td>
                                        <div className="form-check">
                                          <Input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="chk_child"
                                              value="option1"
                                              checked={isChecked}
                                              onChange={() => handleSelectItem(globalIndex)}
                                          />
                                        </div>
                                      </td>
                                      <td className="text-capitalize">{records.kategori}</td>
                                      <td className="text-capitalize">
                                        <Button color="danger" onClick={() => handleDelete(globalIndex)}>Sil</Button>
                                      </td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default Kategori;
