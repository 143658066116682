import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const HesapTuruData = [
  { value: "vadeli", label: "VADELİ" },
  { value: "vadesiz", label: "VADESİZ" },
];
const ParaBirimiData = [
  { value: "tl", label: "TL" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];
const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];


const OnGorulenBakiye = () => {
  const { t } = useTranslation();
  document.title = t("Ön Görülen Bakiye");

  const [firma, setFirma] = useState(null);
  const [banka, setBanka] = useState(null);
  const [sube, setSube] = useState("");
  const [hesapAdi, setHesapAdi] = useState("");
  const [tarih, setTarih] = useState("");
  const [hesapTuru, setHesapTuru] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [records, setRecords] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function hesapTuruIslem(selectedSingle) {
    setHesapTuru(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }

  const tarihIslem = (e) => {
    setTarih(e.target.value);
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!firma || !banka || !sube || !hesapAdi || !tarih || !hesapTuru || !paraBirimi || !durum) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }

    const yeniKayit = {
      firma: firma.label,
      banka: banka.label,
      sube,
      hesapAdi,
      tarih,
      hesapTuru: hesapTuru.label,
      paraBirimi: paraBirimi.label,
      durum: durum.label
    };

    const apiVerisi = [
      {
        banka: "AKBANK",
        bakiye: 453.19,
        blokeBakiye: 0.00,
        onGorulenTahsilat: "dsdsadsa",
        onGorulenOdeme: "dsadasdsad",
        onGorulenBakiye: 453.19,
        onlineBakiye: 453.19,
        bakiyeFark: 0.00,
        paraBirimi: "TRY",
        tahsilatAciklama: "fdgfdgdfg",
        odemeAciklama: "ytryrtytry",
        firma: "ASSISTCAM ASISTANS",
        sube: "İMES TİCARİ",
        iban: "TR111111111111111"
      }
    ]

    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/on-gorulen-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };


  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


  const handleInputChange = (index, field, value) => {
    const updatedRecords = [...records];
    updatedRecords[index][field] = value;
    setRecords(updatedRecords);
  };

  const guncelleIslem = async () => {
    const changedRecords = records.filter((record, index) => (
        record.onGorulenTahsilat || record.onGorulenOdeme || record.tahsilatAciklama || record.odemeAciklama
    ));

    durationnotify("Kayıt başarıyla güncellendi.", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/on-gorulen-bakiye-guncelleme', changedRecords);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //       durationnotify("Kayıt başarıyla güncellendi.", 'success');
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  }

  const toggleModal = (status, data) => {
    setModal(status);
    setModalData(data);
  };

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Ön Görülen Bakiye")} pageTitle={t("Bakiye")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>
                          {/* Şube input */}
                          <div className="mb-3">
                            <Label htmlFor="sube" className="form-label text-capitalize">
                              Şube
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="sube"
                                placeholder="Şube"
                                value={sube}
                                onChange={(e) => setSube(e.target.value)}
                            />
                          </div>
                          {/* Hesap Adı input */}
                          <div className="mb-3">
                            <Label htmlFor="hesapAdi" className="form-label text-capitalize">
                              Hesap Adı
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="hesapAdi"
                                placeholder="Hesap Adı"
                                value={hesapAdi}
                                onChange={(e) => setHesapAdi(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="tarih"
                                    value={tarih}
                                    onChange={tarihIslem}
                                />
                              </Col>
                            </Row>
                          </div>
                          {/* Hesap Türü select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapTuru" className="form-label text-capitalize">
                              Hesap Türü
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapTuru}
                                onChange={hesapTuruIslem}
                                options={HesapTuruData}
                            />
                          </div>
                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>
                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="durum" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3">
                          <Button color="primary" className="d-flex justify-content-center align-items-center"
                                  onClick={() => toggleModal(true, "EXCELDEN YÜKLE")}>
                            <i className="bx bx-plus me-2" style={{fontSize: "20px"}}></i>
                            <span className="text-capitalize">Excelden Yükle</span>
                          </Button>
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">{formatDateString(tarih)} Bakiye</th>
                            <th className="text-capitalize">{formatDateString(tarih)} Bloke Bakiye</th>
                            <th className="text-capitalize">Öngörülen Tahsilat</th>
                            <th className="text-capitalize">Öngörülen Ödeme</th>
                            <th className="text-capitalize">Öngörülen Bakiye</th>
                            <th className="text-capitalize">Online Bakiye</th>
                            <th className="text-capitalize">Bakiye Fark</th>
                            <th className="text-uppercase">P.B</th>
                            <th className="text-capitalize">Tahsilat Açıklama</th>
                            <th className="text-capitalize">Ödeme Açıklama</th>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Şube</th>
                            <th className="text-uppercase">IBAN</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                return (
                                    <tr key={index}>
                                      <td className="text-uppercase">{records.banka}</td>
                                      <td>{formatCurrency(records.bakiye)}</td>
                                      <td>{formatCurrency(records.blokeBakiye)}</td>
                                      <td>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id={`onGorulenTahsilat-${index}`}
                                            placeholder={records.onGorulenTahsilat}
                                            value={records.onGorulenTahsilat}
                                            onChange={(e) => handleInputChange(globalIndex, 'onGorulenTahsilat', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id={`onGorulenOdeme-${index}`}
                                            placeholder={records.onGorulenOdeme}
                                            value={records.onGorulenOdeme}
                                            onChange={(e) => handleInputChange(globalIndex, 'onGorulenOdeme', e.target.value)}
                                        />
                                      </td>
                                      <td>{formatCurrency(records.onGorulenBakiye)}</td>
                                      <td>{formatCurrency(records.onlineBakiye)}</td>
                                      <td>{formatCurrency(records.bakiyeFark)}</td>
                                      <td className="text-uppercase">{records.paraBirimi}</td>
                                      <td>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id={`tahsilatAciklama-${index}`}
                                            placeholder={records.tahsilatAciklama}
                                            value={records.tahsilatAciklama}
                                            onChange={(e) => handleInputChange(globalIndex, 'tahsilatAciklama', e.target.value)}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id={`odemeAciklama-${index}`}
                                            placeholder={records.odemeAciklama}
                                            value={records.odemeAciklama}
                                            onChange={(e) => handleInputChange(globalIndex, 'odemeAciklama', e.target.value)}
                                        />
                                      </td>
                                      <td className="text-uppercase">{records.firma}</td>
                                      <td className="text-uppercase">{records.sube}</td>
                                      <td className="text-uppercase">{records.iban}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                          <Button color="primary" className="me-2 text-capitalize" onClick={() => guncelleIslem()}>Güncelle</Button>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>

        <Modal isOpen={modal} toggle={() => toggleModal(false, "")}>
          <ModalHeader toggle={() => toggleModal(false, "")}>{modalData}</ModalHeader>
          <ModalBody>
            {/* Modal içeriği buraya gelecek */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal(false, "")}>Kapat</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default OnGorulenBakiye;
