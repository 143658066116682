import React from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
//import Scss
import '../../assets/scss/themes.scss';
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const OnlineBanka = () => {
    const { t } = useTranslation();
    document.title = t("Online Banka");

    const history = useNavigate();

    const OnlineBankaSayfaYonlendirme = (id) => {
        history(`/${id}`);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Online Banka" pageTitle="Online Banka" pageLink={null}/>
                    <div>
                        <Col xs={12}>
                            <Col xs={12} lg={8}>
                                <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                    <h5 className="fw-bolder">{t("Hesap İşlemleri")}</h5>
                                </div>
                                <Row>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("hesap-hareketleri")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-bank-transfer la-3x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Hesap Hareketleri")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("yeni-islem")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-account-plus la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Yeni İşlem")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("capraz-virman")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-arrow-collapse la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Çapraz Virman")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("hesap-listesi")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-clipboard-account-outline la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Hesap Listesi")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("manuel-hesaplar")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-clipboard-edit-outline la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Manuel Hesaplar")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("hesap-sorgulama-durumu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-clipboard-search-outline la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-10 timestamp text-muted text-center fw-bolder">
                                                    {t("Hesap Sorgulama Durumu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </Col>

                            <Col xs={12} lg={10}>
                                <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                    <h5 className="fw-bolder">{t("Bakiye")}</h5>
                                </div>
                                <Row>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("online-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-cash-multiple la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Online Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("gun-sonu-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="ri-hand-coin-line la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Gün Sonu Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("yeni-yuruyen-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-cash-fast la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Yeni Yürüyen Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("bakiye-fark-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-chart-areaspline la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Bakiye Fark Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("ortalama-gun-sonu-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="ri-pie-chart-box-line la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Ortalama Gün Sonu Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("on-gorulen-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-cash-marker la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Ön Görülen Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("yeni-on-gorulen-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-cash-plus la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Yeni Ön Görülen Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("saatlik-bakiye")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-hours-24 la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Saatlik Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </Col>

                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} lg={5}>
                                        <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                            <h5 className="fw-bolder">{t("Bir Bakışta")}</h5>
                                        </div>
                                        <Row>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("bir-bakista-online-bakiye")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="las la-donate la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Bir Bakışta Online Bakiye")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("bir-bakista-gun-sonu-bakiye")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="las la-money-bill-wave la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Bir Bakışta Gün Sonu Bakiye")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("bir-bakista-doviz-cevirici")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="las la-exchange-alt la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                            {t("Bir Bakışta Döviz Çevirici")}
                                                        </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("kur-bilgisi")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="las la-file-invoice-dollar la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Kur Bilgisi")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    </Col>
                                    <Col xs={12} lg={5}>
                                        <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                            <h5 className="fw-bolder">{t("Zaman Raporları")}</h5>
                                        </div>
                                        <Row>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("aylik-rapor")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-bar la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Aylık Rapor")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("haftalik-rapor")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-bell-curve-cumulative la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Haftalık Rapor")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("gunluk-rapor")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-bell-curve la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Günlük Rapor")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("saatlik-rapor")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-line la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Saatlik Rapor")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} lg={10}>
                                <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                    <h5 className="fw-bolder">{t("Özet Raporlar")}</h5>
                                </div>
                                <Row>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("banka-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-bank la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Banka Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("cari-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-account-clock la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Cari Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("islem-durum-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-chart-gantt la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("İşlem Durum Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("islem-tipi-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-chart-sankey la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("İşlem Tipi Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("cari-tipi-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-chart-multiple la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Cari Tipi Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("gun-sonu-bakiye-raporu")} style={{width: 110, height: 110, borderRadius: 10}} className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-chart-box-outline la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Gün Sonu Bakiye Raporu")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("pos-donuslu-gun-sonu-raporu")} style={{width: 110, height: 110, borderRadius: 10}}
                                          className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="las la-receipt la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Pos Dönüşlü Gün Sonu Bakiye")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card onClick={() => OnlineBankaSayfaYonlendirme("gruplu-hareket-bakiyesi")} style={{width: 110, height: 110, borderRadius: 10}}
                                          className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                        <CardBody style={{width: "95%", height: "100%"}}
                                                  className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                            <div style={{width: "100%", height: "60%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <i className="mdi mdi-account-cash la-2x"></i>
                                            </div>
                                            <div style={{width: "100%", height: "40%"}}
                                                 className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Gruplu Hareket Bakiyesi")}
                                                </span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </Col>


                            <Col xs={12}>
                                <Row>
                                    <Col xs={6} lg={6}>
                                        <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                            <h5 className="fw-bolder">{t("Nakit Yönetimi")}</h5>
                                        </div>
                                        <Row>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("kategor-bazli-hesap-hareketleri")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-table-arrow-left la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-10 timestamp text-muted text-center fw-bolder">
                                                    {t("Kategori Bazlı Hesap Hareketleri")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("kategori")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-table la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                   {t("Kategori")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("kategor-raporu")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-waterfall la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                   {t("Kategori Raporu")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("kategori-formul-listesi")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-format-list-text la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-11 timestamp text-muted text-center fw-bolder">
                                                    {t("Kategori Formül Listesi")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("banka-bazli-kategori-raporu")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-areaspline-variant la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-10 timestamp text-muted text-center fw-bolder">
                                                    {t("Banka Bazlı Kategori Raporu")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    </Col>

                                    <Col xs={6} lg={4}>
                                        <div style={{width: "100%"}} className="mb-3 border-bottom pb-2 border-2">
                                            <h5 className="fw-bolder">{t("Diğer Raporlar")}</h5>
                                        </div>
                                        <Row>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("hesap-hareket-raporu")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-bar-stacked la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-10 timestamp text-muted text-center fw-bolder">
                                                    {t("Hesap Hareket Raporu")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("manuel-eslestirme-raporu")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-donut-variant la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                <span className="born fs-10 timestamp text-muted text-center fw-bolder">
                                                    {t("Manuel Eşleştirme Raporu")}
                                                </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card onClick={() => OnlineBankaSayfaYonlendirme("eslesme-tipine-gore-rapor")} style={{width: 110, height: 110, borderRadius: 10}}
                                                  className="d-flex justify-content-center align-items-center mx-1 p-0 btn scale-animation">
                                                <CardBody style={{width: "95%", height: "100%"}}
                                                          className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                                                    <div style={{width: "100%", height: "60%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <i className="mdi mdi-chart-line-stacked la-2x"></i>
                                                    </div>
                                                    <div style={{width: "100%", height: "40%"}}
                                                         className="d-flex justify-content-center align-items-center">
                                                        <span className="born fs-10 timestamp text-muted text-center fw-bolder">
                                                            {t("Eşleşme Tipine Göre Rapor")}
                                                        </span>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OnlineBanka;
