import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
  { value: "banka3", label: "Banka 3" },
];

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
  { value: "firma4", label: "Firma 3" },
];

const ParaBirimiData = [
  { value: "TRY", label: "TRY" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
];

const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];


const ManuelHesaplar = () => {
  const { t } = useTranslation();
  document.title = t("Manuel Hesaplar");

  const [banka, setBanka] = useState(null);
  const [firma, setFirma] = useState(null);
  const [sube, setSube] = useState("");
  const [iban, setIban] = useState("");
  const [paraBirimi, setParaBirimi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [manuelHesaplar, setManuelHesaplar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }
  const EkleIslem = async () => {
    if (!banka || !firma || !sube || !iban || !paraBirimi || !durum) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniManuelHesaplar = [
      {
        banka: banka?.label,
        firma: firma?.label,
        sube,
        iban,
        paraBirimi: paraBirimi.label,
        durum: durum.label
      }
    ]
    setManuelHesaplar([...manuelHesaplar, ...yeniManuelHesaplar]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/manuel-hesaplar', yeniManuelHesaplar);
    //   if (response.data.success) {
    //      setManuelHesaplar([...manuelHesaplar, yeniManuelHesaplar]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  // geçici buton
  const handleDelete = (index) => {
    const newManuelHesaplar = manuelHesaplar.filter((_, i) => i !== index);
    setManuelHesaplar(newManuelHesaplar);
    durationnotify("Kayıt başarıyla silindi.", 'info');
  };
  // const handleDelete = async (index) => {
  //   const itemToDelete = hesapListesi[index];
  //   try {
  //     const response = await axios.delete(`/api/manuel-hesaplar/${itemToDelete.id}`);
  //     if (response.data.success) {
  //         const newManuelHesaplar = manuelHesaplar.filter((_, i) => i !== index);
  //         setManuelHesaplar(newManuelHesaplar);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };


  const totalPages = Math.ceil(manuelHesaplar.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedManuelHesaplar = manuelHesaplar.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = displayedManuelHesaplar.map((_, index) => (currentPage - 1) * itemsPerPage + index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // geçici buton
  const handleBulkDelete = () => {
    const newManuelHesaplar = manuelHesaplar.filter((_, index) => !selectedItems.includes(index));
    setManuelHesaplar(newManuelHesaplar);
    setSelectedItems([]);
    durationnotify("Seçilen kayıtlar başarıyla silindi.", 'info');
  };
  // const handleBulkDelete = async () => {
  //   const itemsToDelete = selectedItems.map(index => hesapListesi[index]);
  //   try {
  //     const response = await axios.post('/api/manuel-hesaplar/bulk-delete', { items: itemsToDelete });
  //     if (response.data.success) {
  //       const newManuelHesaplar = manuelHesaplar.filter((_, index) => !selectedItems.includes(index));
  //       setManuelHesaplar(newManuelHesaplar);
  //       setSelectedItems([]);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  const toggleModal = (status, data) => {
    setModal(status);
    setModalData(data);
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Manuel Hesaplar")} pageTitle={t("Hesap İşlemleri")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Şube input */}
                          <div className="mb-3">
                            <Label htmlFor="sube" className="form-label text-capitalize">
                              Şube
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="sube"
                                placeholder="Şube"
                                value={sube}
                                onChange={(e) => setSube(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* IBAN input */}
                          <div className="mb-3">
                            <Label htmlFor="iban" className="form-label text-capitalize">
                              IBAN
                            </Label>
                            <Input
                                type="number"
                                className="form-control text-capitalize"
                                id="iban"
                                placeholder="IBAN"
                                value={iban}
                                onChange={(e) => setIban(e.target.value)}
                            />
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="islemTipi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>

                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="islemTipi" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3 d-flex">
                            <Button color="primary" className="d-flex justify-content-center align-items-center me-1"
                                    onClick={() => handleBulkDelete} disabled={selectedItems.length === 0}>
                              <i className="bx bx-plus me-2" style={{fontSize: "20px"}}></i>
                              <span className="text-capitalize">Manuel Hesap Ekle</span>
                            </Button>

                            <Button color="primary" className="d-flex justify-content-center align-items-center ms-1"
                                    onClick={() => handleBulkDelete} disabled={selectedItems.length === 0}>
                              <i className="bx bx-plus me-2" style={{fontSize: "20px"}}></i>
                              <span className="text-capitalize">Toplu Manuel Hesap Ekle</span>
                            </Button>
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            {/*<th scope="col" style={{ width: "50px" }}>*/}
                            {/*  <div className="form-check">*/}
                            {/*    <Input*/}
                            {/*        className="form-check-input"*/}
                            {/*        type="checkbox"*/}
                            {/*        id="checkAll"*/}
                            {/*        value="option"*/}
                            {/*        onChange={handleSelectAll}*/}
                            {/*        checked={selectedItems.length === displayedManuelHesaplar.length}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</th>*/}
                            <th className="text-capitalize">Durum</th>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Şube</th>
                            <th className="text-uppercase">IBAN</th>
                            <th className="text-capitalize">Hesap Adı</th>
                            <th className="text-capitalize">Hesap türü</th>
                            <th className="text-uppercase">P.B</th>
                            {/*<th className="text-capitalize">Sil</th>*/}
                          </tr>
                          </thead>
                          {displayedManuelHesaplar.length >= 1 && (
                              <tbody>
                              {displayedManuelHesaplar.map((manuelHesaplar, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      {/*<td>*/}
                                      {/*  <div className="form-check">*/}
                                      {/*    <Input*/}
                                      {/*        className="form-check-input"*/}
                                      {/*        type="checkbox"*/}
                                      {/*        name="chk_child"*/}
                                      {/*        value="option1"*/}
                                      {/*        checked={isChecked}*/}
                                      {/*        onChange={() => handleSelectItem(globalIndex)}*/}
                                      {/*    />*/}
                                      {/*  </div>*/}
                                      {/*</td>*/}
                                      <td>
                                        <div style={{width: 20, height: 20, borderRadius: 100}}
                                             className={`bg-gradient 
                                             ${manuelHesaplar.durum === "AKTİF" && "bg-success"} 
                                             ${manuelHesaplar.durum === "PASİF" && "bg-danger"}`}>
                                        </div>
                                      </td>
                                      <td className="text-uppercase">{manuelHesaplar.banka}</td>
                                      <td className="text-uppercase">{manuelHesaplar.firma}</td>
                                      <td className="text-uppercase">{manuelHesaplar.sube}</td>
                                      <td className="text-uppercase">{manuelHesaplar.iban}</td>
                                      <td className="text-capitalize">{manuelHesaplar.hesapAdi}</td>
                                      <td className="text-uppercase">{manuelHesaplar.hesapTuru}</td>
                                      <td className="text-uppercase">{manuelHesaplar.paraBirimi}</td>
                                      {/*<td className="text-capitalize">*/}
                                      {/*  <Button color="danger" onClick={() => handleDelete(globalIndex)}>Sil</Button>*/}
                                      {/*</td>*/}
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedManuelHesaplar.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>

        <Modal isOpen={modal} toggle={() => toggleModal(false, "")}>
          <ModalHeader toggle={() => toggleModal(false, "")}>{modalData}</ModalHeader>
          <ModalBody>
            {/* Modal içeriği buraya gelecek */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal(false, "")}>Kapat</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default ManuelHesaplar;
