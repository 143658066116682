import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];
const HesapAdiData = [
  { value: "hesap1", label: "Hesap 1" },
  { value: "hesap2", label: "Hesap 2" },
];
const HesapTuruData = [
  { value: "vadeli", label: "VADELİ" },
  { value: "vadesiz", label: "VADESİZ" },
];
const ParaBirimiData = [
  { value: "tl", label: "TL" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];
const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];

const SaatlikBakiye = () => {
  const { t } = useTranslation();
  document.title = t("Saatlik Bakiye");

  const [banka, setBanka] = useState(null);
  const [firma, setFirma] = useState(null);
  const [sube, setSube] = useState(null);
  const [hesapAdi, setHesapAdi] = useState(null);
  const [tarih, setTarih] = useState("");
  const [saat, setSaat] = useState("");
  const [hesapTuru, setHesapTuru] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const [table1, setTable1] = useState([]);
  const [table2, setTable2] = useState([]);
  const [table3, setTable3] = useState([]);
  const [table4, setTable4] = useState([]);
  const [table5, setTable5] = useState([]);
  const [table6, setTable6] = useState([]);
  const [table7, setTable7] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }
  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function hesapAdiIslem(selectedSingle) {
    setHesapAdi(selectedSingle);
  }
  const tarihIslem = (e) => {
    setTarih(e.target.value);
  };
  const saatIslem = (e) => {
    setSaat(e.target.value);
  };
  function hesapTuruIslem(selectedSingle) {
    setHesapTuru(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!banka || !firma || !sube || !hesapAdi || !tarih || !saat || !hesapTuru || !paraBirimi || !durum) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }

    const yeniKayit = {
      banka: banka.label,
      firma: firma.label,
      sube,
      hesapAdi: hesapAdi.label,
      tarih,
      saat,
      hesapTuru: hesapTuru.label,
      paraBirimi: paraBirimi.label,
      durum: durum.label,
    };

    const table1Data = [
      {
        paraBirimi: "TURK LİRASI",
        toplamBakiye: 646906.94,
        toplamBlokeBakiye: 0.00
      }
    ]
    const table2Data = [
      {
        firma: "ASSISTCAM ASSISTANS",
        try: 4997.22,
        usd: 0.00,
        eur: 0.00
      },
      {
        firma: "DİGİLERA BİLGİ TEKNOLOJİLERİ",
        try: 4997.22,
        usd: 0.00,
        eur: 0.00
      },
      {
        firma: "OPTİMUM OTOMOTİV",
        try: 4997.22,
        usd: 0.00,
        eur: 0.00
      }
    ]
    const table3Data = [
      {
        banka: "AKBANK",
        try: 4997.22,
        usd: 0.00,
        eur: 0.00
      }
    ]
    const table4Data = [
      {
        hesapTuru: "AKBANK",
        try: 4997.22,
        usd: 0.00,
        eur: 0.00
      }
    ]
    const table5Data = [
      {
        banka: "AKBANK",
        bakiye: 4997.22,
        blokeBakiye: 0.00,
        pb: yeniKayit.paraBirimi,
        firma: yeniKayit.firma,
        sube: yeniKayit.sube,
        iban: "TR1111111111111",
        hesapAdi: yeniKayit.hesapAdi,
        hesapTuru: yeniKayit.hesapTuru
      }
    ]
    const table6Data = [
      {
        hesapAdi: "AKBANK",
        try: 4997.22,
        usd: 0.00,
        eur: 0.00
      }
    ]
    const table7Data = [
      {
        firmalar: [
          {
            firma: "ASSISTCAM ASSISTANS",
            bakiye: 453.19,
          },
          {
            firma: "DİGİLERA BİLGİ TEKNOLOJİLERİ",
            bakiye: 58132.38,
          },
          {
            firma: "OPTİMUM OTOMOTİV",
            bakiye: 19874.50,
          }
        ],
        banka: "AKBANK",
        pb:"TRY",
      }
    ]

    setTable1([...table1, ...table1Data]);
    setTable2([...table2, ...table2Data]);
    setTable3([...table3, ...table3Data]);
    setTable4([...table4, ...table4Data]);
    setTable5([...table5, ...table5Data]);
    setTable6([...table6, ...table6Data]);
    setTable7([...table7, ...table7Data]);

    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/capraz-virman', yeniKayit);
    //   if (response.data.success) {
    //       setTable1([...table1, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const table1TotalPages = Math.ceil(table1.length / itemsPerPage);
  const table2TotalPages = Math.ceil(table2.length / itemsPerPage);
  const table3TotalPages = Math.ceil(table3.length / itemsPerPage);
  const table4TotalPages = Math.ceil(table4.length / itemsPerPage);
  const table5TotalPages = Math.ceil(table5.length / itemsPerPage);
  const table6TotalPages = Math.ceil(table6.length / itemsPerPage);
  const table7TotalPages = Math.ceil(table7.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedTable1 = table1.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const displayedTable2 = table2.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const displayedTable3 = table3.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const displayedTable4 = table4.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const displayedTable5 = table5.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const displayedTable6 = table6.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const displayedTable7 = table7.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const toggleModal = (status, data) => {
    setModal(status);
    setModalData(data);
  };

  const getUniqueFirmaNames = (records) => {
    const firmNames = new Set();
    records.forEach(record => {
      record.firmalar.forEach(firma => {
        firmNames.add(firma.firma);
      });
    });
    return Array.from(firmNames);
  };

  const uniqueFirmaNamesTable7 = getUniqueFirmaNames(table7);

  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Saatlik Bakiye")} pageTitle={t("Bakiye")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* listeleme input kısmı */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*durum, tarih saat ve bitiş*/}
                      <Row className="mt-1 mb-3">
                        <Col xs={12}>
                          <Row>
                            <Col xs={12} md={8} className="d-flex align-items-center">
                              <div className="d-flex">
                                <div className="d-flex py-2 px-3 me-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                                  <div className="me-2 text-uppercase" style={{fontWeight: 500}}>
                                    TARİH VE SAAT - BİTİŞ:
                                  </div>
                                  <div className="text-danger" style={{fontWeight: 500}}>
                                    {tarih || saat ? formatDateString(tarih) + ' - ' + saat : "-"}
                                  </div>
                                </div>

                                <div className="d-flex py-2 px-3 ms-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                                  <i className={`mdi me-2 ${durum?.value === "aktif" ? "mdi-check-bold text-success" : durum?.value === "pasif" ? "mdi-close-thick text-danger" : ""}`}></i>

                                  <div className="me-2 text-uppercase" style={{fontWeight: 500}}>
                                    DURUM:
                                  </div>
                                  <div className="text-danger" style={{fontWeight: 500}}>
                                    {durum ? durum.label : '-'}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} md={4} className="d-flex justify-content-start justify-content-md-end mt-3 mb-md-0">
                              <button className="btn btn-primary p-0 m-0" style={{width: 40, height: 40}}  onClick={() => toggleModal(true, "FİLTRELE")}>
                                <i className="ri-filter-2-fill" style={{ fontSize: '24px' }}></i>
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* verileri ekleme */}
                      <Row>
                        {/*inputlar, selectler ve listele buton*/}
                        <Col lg={6}>
                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>

                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Şube input */}
                          <div className="mb-3">
                            <Label htmlFor="sube" className="form-label text-capitalize">
                              Şube
                            </Label>
                            <Input
                                type="text"
                                className="form-control text-capitalize"
                                id="sube"
                                placeholder="Şube"
                                value={sube}
                                onChange={(e) => setSube(e.target.value)}
                            />
                          </div>

                          {/* Hesap Adı select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapAdi" className="form-label text-capitalize">
                              Hesap Adı
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapAdi}
                                onChange={hesapAdiIslem}
                                options={HesapAdiData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih ve Saat input */}
                          <div className="mb-3">
                            <Row>
                              <Col>
                                <Label htmlFor="tarih" className="form-label text-capitalize">
                                  Tarih
                                </Label>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="tarih"
                                    value={tarih}
                                    onChange={tarihIslem}
                                />
                              </Col>
                              <Col>
                                <Label htmlFor="saat" className="form-label text-capitalize">
                                  Saat
                                </Label>
                                <Input
                                    type="time"
                                    className="form-control"
                                    id="saat"
                                    value={saat}
                                    onChange={saatIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Hesap Türü select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapTuru" className="form-label text-capitalize">
                              Hesap Türü
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapTuru}
                                onChange={hesapTuruIslem}
                                options={HesapTuruData}
                            />
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>

                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="durum" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              {/* listeleme kısmı table 1 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th style={{width: 55}}></th>
                            <th className="text-capitalize">Para Birimi</th>
                            <th className="text-capitalize">Toplam Bakiye</th>
                            <th className="text-capitalize">Toplam Bloke Bakiye</th>
                          </tr>
                          </thead>

                          {displayedTable1?.length >= 1 && (
                              <tbody>
                              {displayedTable1?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="d-flex justify-content-center align-items-center">
                                        <i className="bx bx-plus" style={{fontSize: "20px"}}></i>
                                      </td>
                                      <td className="text-uppercase">{records.paraBirimi}</td>
                                      <td>{formatCurrency(records.toplamBakiye)}</td>
                                      <td>{formatCurrency(records.toplamBlokeBakiye)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable1?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table1TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table1TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* listeleme kısmı table 2 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-uppercase">TRY</th>
                            <th className="text-uppercase">USD</th>
                            <th className="text-uppercase">EUR</th>
                          </tr>
                          </thead>

                          {displayedTable2?.length >= 1 && (
                              <tbody>
                              {displayedTable2?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.firma}</td>
                                      <td>{formatCurrency(records.try)}</td>
                                      <td>{formatCurrency(records.usd)}</td>
                                      <td>{formatCurrency(records.eur)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable2?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table2TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table2TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* listeleme kısmı table 3 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-uppercase">TRY</th>
                            <th className="text-uppercase">USD</th>
                            <th className="text-uppercase">EUR</th>
                          </tr>
                          </thead>

                          {displayedTable3?.length >= 1 && (
                              <tbody>
                              {displayedTable3?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-uppercase">{records.banka}</td>
                                      <td>{formatCurrency(records.try)}</td>
                                      <td>{formatCurrency(records.usd)}</td>
                                      <td>{formatCurrency(records.eur)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable3?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table3TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table3TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* listeleme kısmı table 4 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Hesap Türü</th>
                            <th className="text-uppercase">TRY</th>
                            <th className="text-uppercase">USD</th>
                            <th className="text-uppercase">EUR</th>
                          </tr>
                          </thead>

                          {displayedTable4?.length >= 1 && (
                              <tbody>
                              {displayedTable4?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-capitalize">{records.hesapTuru}</td>
                                      <td>{formatCurrency(records.try)}</td>
                                      <td>{formatCurrency(records.usd)}</td>
                                      <td>{formatCurrency(records.eur)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable4?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table4TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table4TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* listeleme kısmı table 5 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">Bakiye</th>
                            <th className="text-capitalize">Bloke Bakiye</th>
                            <th className="text-uppercase">P.B</th>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Şube</th>
                            <th className="text-uppercase">IBAN</th>
                            <th className="text-capitalize">Hesap Adı</th>
                            <th className="text-capitalize">Hesap Türü</th>
                          </tr>
                          </thead>

                          {displayedTable5?.length >= 1 && (
                              <tbody>
                              {displayedTable5?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-capitalize">{records.banka}</td>
                                      <td>{formatCurrency(records.bakiye)}</td>
                                      <td>{formatCurrency(records.blokeBakiye)}</td>
                                      <td className="text-uppercase">{records.pb}</td>
                                      <td className="text-capitalize">{records.firma}</td>
                                      <td className="text-capitalize">{records.sube}</td>
                                      <td className="text-uppercase">{records.iban}</td>
                                      <td className="text-capitalize">{records.hesapAdi}</td>
                                      <td className="text-uppercase">{records.hesapTuru}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable5?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table5TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table5TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* listeleme kısmı table 6 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Hesap Adı</th>
                            <th className="text-uppercase">TRY</th>
                            <th className="text-uppercase">USD</th>
                            <th className="text-uppercase">EUR</th>
                          </tr>
                          </thead>

                          {displayedTable6?.length >= 1 && (
                              <tbody>
                              {displayedTable6?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-capitalize">{records.hesapAdi}</td>
                                      <td>{formatCurrency(records.try)}</td>
                                      <td>{formatCurrency(records.usd)}</td>
                                      <td>{formatCurrency(records.eur)}</td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable6?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table6TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table6TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* listeleme kısmı table 7 */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-uppercase">P.B</th>
                            {uniqueFirmaNamesTable7.map((firma, index) => (
                                <th key={index} className="text-capitalize">{firma}</th>
                            ))}
                          </tr>
                          </thead>

                          {displayedTable7?.length >= 1 && (
                              <tbody>
                              {displayedTable7?.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="text-capitalize">{records.banka}</td>
                                      <td className="text-uppercase">{records.pb}</td>
                                      {uniqueFirmaNamesTable7.map((firma, firmaIndex) => {
                                        const firm = records.firmalar.find(f => f.firma === firma);
                                        return (
                                            <td key={firmaIndex}>
                                              {formatCurrency(firm ? firm.bakiye : 0.00)}
                                            </td>
                                        );
                                      })}
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedTable7?.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div></div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(table7TotalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === table7TotalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>

        <Modal isOpen={modal} toggle={() => toggleModal(false, "")}>
          <ModalHeader toggle={() => toggleModal(false, "")}>{modalData}</ModalHeader>
          <ModalBody>
            {/* Modal içeriği buraya gelecek */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal(false, "")}>Kapat</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
  );
};

export default SaatlikBakiye;
