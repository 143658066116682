import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import {useTranslation} from "react-i18next";

const OrtalamaGunSonuBakiye = () => {
  const { t } = useTranslation();
  document.title = t("Ortalama Gün Sonu Bakiye");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Ortalama Gün Sonu Bakiye")} pageTitle={t("Bakiye")} pageLink="online-banka"  />
          <Row>
            <Col xs={12}>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrtalamaGunSonuBakiye;
