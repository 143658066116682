import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container, Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const BakiyeTipiData = [
  { value: "bakiyeTipi1", label: "Bakiye Tipi 1" },
  { value: "bakiyeTipi2", label: "Bakiye Tipi 2" },
];
const HesapAdiData = [
  { value: "hesap1", label: "Hesap 1" },
  { value: "hesap2", label: "Hesap 2" },
];
const DurumData = [
  { value: "aktif", label: "AKTİF" },
  { value: "pasif", label: "PASİF" },
];
const HesapTuruData = [
  { value: "vadeli", label: "VADELİ" },
  { value: "vadesiz", label: "VADESİZ" },
];

const BirBakistaGunSonuBakiye = () => {
  const { t } = useTranslation();
  document.title = t("Bir Bakışta Gün Sonu Bakiye");

  const [bakiyeTipi, setBakiyeTipi] = useState(null);
  const [hesapAdi, setHesapAdi] = useState(null);
  const [durum, setDurum] = useState(null);
  const [tarih, setTarih] = useState("");
  const [hesapTuru, setHesapTuru] = useState(null);
  const [records, setRecords] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function bakiyeTipiIslem(selectedSingle) {
    setBakiyeTipi(selectedSingle);
  }
  function hesapAdiIslem(selectedSingle) {
    setHesapAdi(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }
  const tarihiIslem = (e) => {
    setTarih(e.target.value);
  };
  function hesapTuruIslem(selectedSingle) {
    setHesapTuru(selectedSingle);
  }

  const EkleIslem = async () => {
    if (!bakiyeTipi || !hesapAdi || !durum || !hesapTuru || !tarih) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniKayit = {
      bakiyeTipi: bakiyeTipi.label,
      hesapAdi: hesapAdi.label,
      durum: durum.label,
      hesapTuru: hesapTuru.label,
      tarih,
    };

    const apiVerisi = [
      {
        firma: "ASSISTCAM ASISTANS",
        try: 4997.22,
        eur: 0.00,
        usd: 0.00,
      },
      {
        firma: "DİGİLERA BİLGİ TEKNOLOJİLERİ",
        try: 61831.83,
        eur: 0.00,
        usd: 0.00,
      },
      {
        firma: "OPTİMUM OTOMOTİV",
        try: 522404.55,
        eur: 0.00,
        usd: 58.94,
      },
    ]

    setRecords([...records, ...apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/bir-bakista-gun-sonu-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const calculateTotal = (records, field, isCurrency = false) => {
    if (!Array.isArray(records) || records.length === 0) return isCurrency ? '0,00' : '0';
    const total = records.reduce((acc, record) => {
      const value = isCurrency ? parseFloat(record[field] || 0) : parseInt(record[field] || 0, 10);
      return acc + value;
    }, 0);
    return isCurrency ? total.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : total.toLocaleString('tr-TR');
  };
  const formatCurrency = (value, decimals = 2) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Bir Bakışta Gün Sonu Bakiye")} pageTitle={t("Bir Bakışta")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* veri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Bakiye Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="bakiyeTipi" className="form-label text-capitalize">
                              Bakiye Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={bakiyeTipi}
                                onChange={bakiyeTipiIslem}
                                options={BakiyeTipiData}
                            />
                          </div>

                          {/* Hesap Adı select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapAdi" className="form-label text-capitalize">
                              Hesap Adı
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapAdi}
                                onChange={hesapAdiIslem}
                                options={HesapAdiData}
                            />
                          </div>

                          {/* Durum select */}
                          <div className="mb-3">
                            <Label htmlFor="durum" className="form-label text-capitalize">
                              Durum
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={durum}
                                onChange={durumIslem}
                                options={DurumData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="tarihi"
                                    value={tarih}
                                    onChange={tarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Hesap Türü select */}
                          <div className="mb-3">
                            <Label htmlFor="hesapTuru" className="form-label text-capitalize">
                              Hesap Türü
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hesapTuru}
                                onChange={hesapTuruIslem}
                                options={HesapTuruData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th style={{width: 55}}></th>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">TRY</th>
                            <th className="text-capitalize">EUR</th>
                            <th className="text-capitalize">USD</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      <td className="d-flex justify-content-center">
                                        <i className="bx bx-plus" style={{fontSize: "20px"}}></i>
                                      </td>
                                      <td className="text-uppercase">{records.firma}</td>
                                      <td>{formatCurrency(records.try)}</td>
                                      <td>{formatCurrency(records.eur)}</td>
                                      <td>{formatCurrency(records.usd)}</td>
                                    </tr>
                                );
                              })}


                              {/* Toplam Satırı */}
                              <tr className="table-light">
                                <td></td>
                                <td className="text-uppercase" style={{ fontWeight: "bold" }}>TOPLAM</td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records, 'try', true)}
                                </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records, 'eur', true)}
                                </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records, 'usd', true)}
                                </td>
                              </tr>
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default BirBakistaGunSonuBakiye;
