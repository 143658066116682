import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];

const BankaData = [
  { value: "banka1", label: "Banka 1" },
  { value: "banka2", label: "Banka 2" },
];


const CaprazVirman = () => {
  const { t } = useTranslation();
  document.title = t("Çapraz Virman");

  const [firma, setFirma] = useState(null);
  const [banka, setBanka] = useState(null);
  const [banka2, setBanka2] = useState(null);
  const [baslangicTarihi, setBaslangicTarihi] = useState("");
  const [bitisTarihi, setBitisTarihi] = useState("");
  const [records, setRecords] = useState([]);

  const [modal, setModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }

  function bankaIslem(selectedSingle) {
    setBanka(selectedSingle);
  }

  function banka2Islem(selectedSingle) {
    setBanka2(selectedSingle);
  }

  const baslangicTarihiIslem = (e) => {
    setBaslangicTarihi(e.target.value);
  };
  const bitisTarihiIslem = (e) => {
    setBitisTarihi(e.target.value);
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!firma || !banka || !banka2 || !baslangicTarihi || !bitisTarihi) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi < baslangicTarihi) {
      durationnotify("bitiş tarihi başlangıç tarihinden küçük olamaz!", 'danger');
      return;
    }
    const yeniKayit = [
      {
        firma: firma.label,
        banka: banka.label,
        banka2: banka2.label,
        baslangicTarihi,
        bitisTarihi
      }
    ]
    setRecords([...records, ...yeniKayit]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/capraz-virman', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  // geçici buton
  const handleDelete = (index) => {
    const newRecords = records.filter((_, i) => i !== index);
    setRecords(newRecords);
    durationnotify("Kayıt başarıyla silindi.", 'info');
  };
  // const handleDelete = async (index) => {
  //   const itemToDelete = records[index];
  //   try {
  //     const response = await axios.delete(`/api/capraz-virman-delete/${itemToDelete.id}`);
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, i) => i !== index);
  //         setRecords(newRecords);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };


  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = displayedRecords.map((_, index) => (currentPage - 1) * itemsPerPage + index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };


  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // geçici buton
  const handleBulkDelete = () => {
    const newRecords = records.filter((_, index) => !selectedItems.includes(index));
    setRecords(newRecords);
    setSelectedItems([]);
    durationnotify("Seçilen kayıtlar başarıyla silindi.", 'info');
  };
  // const handleBulkDelete = async () => {
  //   const itemsToDelete = selectedItems.map(index => hareketler[index]);
  //   try {
  //     const response = await axios.post('/api/hareketler/bulk-delete', { items: itemsToDelete });
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, index) => !selectedItems.includes(index));
  //       setHareketler(newHareketler);
  //       setSelectedItems([]);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Çapraz Virman")} pageTitle={t("Hesap İşlemleri")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                          marginTop: 20,
                          marginBottom: 20,
                          marginLeft: 25,
                          marginRight: 25,
                        }}>

                      {/*tarih başlangıç ve bitiş*/}
                      <Row className="mt-1 mb-3">
                        <Col xs={12}>
                          <div className="d-flex">
                            <div className="d-flex py-2 px-3 me-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                              <div className="me-2 text-uppercase" style={{fontWeight: 500}}>
                                TARİH - BAŞLANGIÇ:
                              </div>
                              <div className="text-danger" style={{fontWeight: 500}}>
                                {baslangicTarihi ? formatDateString(baslangicTarihi) : "-"}
                              </div>
                            </div>

                            <div className="d-flex py-2 px-3 ms-2" style={{border: '1px solid #e9ebec', borderRadius: 10}}>
                              <div className="me-2 text-uppercase" style={{fontWeight: 500}}>
                                TARİH - BİTİŞ:
                              </div>
                              <div className="text-danger" style={{fontWeight: 500}}>
                                {bitisTarihi ? formatDateString(bitisTarihi) : "-"}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Banka select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka}
                                onChange={bankaIslem}
                                options={BankaData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi"
                                    value={baslangicTarihi}
                                    onChange={baslangicTarihiIslem}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi"
                                    value={bitisTarihi}
                                    onChange={bitisTarihiIslem}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Banka2 select */}
                          <div className="mb-3">
                            <Label htmlFor="banka" className="form-label text-capitalize">
                              Banka
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={banka2}
                                onChange={banka2Islem}
                                options={BankaData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3 text-capitalize">
                          <Button color="danger" onClick={handleBulkDelete} disabled={selectedItems.length === 0}>
                            Toplu Sil
                          </Button>
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}>
                              <div className="form-check">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                    onChange={handleSelectAll}
                                    checked={selectedItems.length === displayedRecords.length}
                                />
                              </div>
                            </th>
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">Tarih</th>
                            <th className="text-capitalize">Banka</th>
                            <th className="text-capitalize">Sil</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                                {displayedRecords.map((records, index) => {
                                  const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                  const isChecked = selectedItems.includes(globalIndex);
                                  return (
                                      <tr key={index} className={isChecked ? "table-active" : ""}>
                                        <td>
                                          <div className="form-check">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="chk_child"
                                                value="option1"
                                                checked={isChecked}
                                                onChange={() => handleSelectItem(globalIndex)}
                                            />
                                          </div>
                                        </td>
                                        <td className="text-uppercase">{records.firma}</td>
                                        <td className="text-uppercase">{records.banka}</td>
                                        <td>{formatDateString(records.baslangicTarihi)} - {formatDateString(records.bitisTarihi)}</td>
                                        <td className="text-uppercase">{records.banka2}</td>

                                        <td className="text-capitalize">
                                          <Button color="danger" onClick={() => handleDelete(globalIndex)}>Sil</Button>
                                        </td>
                                      </tr>
                                  );
                                })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                          <Button color="success" className="me-2 text-uppercase">ÇAPRAZ VİRMAN İŞARETLE</Button>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default CaprazVirman;
