import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  Table
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const CariTipiData = [
  { value: "Bireysel", label: "Bireysel" },
  { value: "Kurumsal", label: "Kurumsal" },
];

const SmsTanimiData = [
  { value: "Bilgi", label: "Bilgi" },
  { value: "İndirim", label: "İndirim" },
  { value: "Güncelleme", label: "Güncelleme" },
];

const KomisyonGrubuData = [
  { value: "10", label: "10%" },
  { value: "20", label: "20%" },
  { value: "30", label: "30%" },
  { value: "40", label: "40%" },
];

const DurumData = [
  { value: "AKTİF", label: "AKTİF" },
  { value: "PASİF", label: "PASİF" },
];

const CariScreen = () => {
  const { t } = useTranslation();
  document.title = t("Cari");

  const [paraTransferKodu, setParaTransferKodu] = useState("");
  const [cariAdi, setCariAdi] = useState("");
  const [cariTipi, setCariTipi] = useState(null);
  const [yetkiliAdSoyad, setYetkiliAdSoyad] = useState("");
  const [vergiNumarasi, setVergiNumarasi] = useState("");
  const [isAlani, setIsAlani] = useState("");
  const [hesapKodu, setHesapKodu] = useState("");
  const [ozelAlan, setOzelAlan] = useState("");
  const [smsTanimi, setSmsTanimi] = useState(null);
  const [komisyonGrubu, setKomisyonGrubu] = useState(null);
  const [durum, setDurum] = useState(null);

  const [cariListesi, setCariListesi] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function cariTipiIslem(selectedSingle) {
    setCariTipi(selectedSingle);
  }
  function smsTanimiIslem(selectedSingle) {
    setSmsTanimi(selectedSingle);
  }
  function komisyonGrubuIslem(selectedSingle) {
    setKomisyonGrubu(selectedSingle);
  }
  function durumIslem(selectedSingle) {
    setDurum(selectedSingle);
  }

  const EkleIslem = async () => {
    if (!paraTransferKodu || !cariAdi || !cariTipi || !yetkiliAdSoyad || !vergiNumarasi || !isAlani ||
        !hesapKodu || !ozelAlan || !smsTanimi || !komisyonGrubu || !durum) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniCari = {
      paraTransferKodu,
      cariAdi,
      cariTipi: cariTipi?.label,
      yetkiliAdSoyad,
      vergiNumarasi,
      isAlani,
      hesapKodu,
      ozelAlan,
      smsTanimi: smsTanimi?.label,
      komisyonGrubu: komisyonGrubu?.label,
      durum: durum?.label
    };
    setCariListesi([...cariListesi, yeniCari]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/cari', yeniCari);
    //   if (response.data.success) {
    //     setCariListesi([...cariListesi, yeniCari]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  // geçici buton
  const handleDelete = (index) => {
    const newCariListesi = cariListesi.filter((_, i) => i !== index);
    setCariListesi(newCariListesi);
    durationnotify("Kayıt başarıyla silindi.", 'info');
  };
  // const handleDelete = async (index) => {
  //   const itemToDelete = cariListesi[index];
  //   try {
  //     const response = await axios.delete(`/api/cari/${itemToDelete.id}`);
  //     if (response.data.success) {
  //       const newCariListesi = cariListesi.filter((_, i) => i !== index);
  //       setCariListesi(newCariListesi);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  const totalPages = Math.ceil(cariListesi.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedCariListesi = cariListesi.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = displayedCariListesi.map((_, index) => (currentPage - 1) * itemsPerPage + index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // geçici buton
  const handleBulkDelete = () => {
    const newCariListesi = cariListesi.filter((_, index) => !selectedItems.includes(index));
    setCariListesi(newCariListesi);
    setSelectedItems([]);
    durationnotify("Seçilen kayıtlar başarıyla silindi.", 'info');
  };
  // const handleBulkDelete = async () => {
  //   const itemsToDelete = selectedItems.map(index => cariListesi[index]);
  //   try {
  //     const response = await axios.post('/api/cari/bulk-delete', { items: itemsToDelete });
  //     if (response.data.success) {
  //       const newCariListesi = cariListesi.filter((_, index) => !selectedItems.includes(index));
  //       setCariListesi(newCariListesi);
  //       setSelectedItems([]);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Cari Listesi")} pageTitle={t("Cari")} pageLink={null} />
            <ToastContainer />

            {/* ekleme kısmı */}
            <Row>
              <Col lg={12}>
                <Card>
                  <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        marginLeft: 25,
                        marginRight: 25,
                      }}
                  >
                    <Row>
                      <Col lg={6}>
                        {/* Para Transfer Kodu */}
                        <div className="mb-3">
                          <Label htmlFor="paraTransferKodu" className="form-label text-capitalize">
                            Para Transfer Kodu
                          </Label>
                          <Input
                              type="text"
                              className="form-control text-capitalize"
                              id="paraTransferKodu"
                              placeholder="Para Transfer Kodu"
                              value={paraTransferKodu}
                              onChange={(e) => setParaTransferKodu(e.target.value)}
                          />
                        </div>
                        {/* cari adı */}
                        <div className="mb-3">
                          <Label htmlFor="cariAdi" className="form-label text-capitalize">
                            Cari Adı
                          </Label>
                          <Input
                              type="text"
                              className="form-control text-capitalize"
                              id="cariAdi"
                              placeholder=" Cari Adı"
                              value={cariAdi}
                              onChange={(e) => setCariAdi(e.target.value)}
                          />
                        </div>
                        {/* Cari Tipi */}
                        <div className="mb-3">
                          <Label htmlFor="cariTipi" className="form-label text-capitalize">
                            Cari Tipi
                          </Label>
                          <Select
                              className="text-capitalize"
                              value={cariTipi}
                              onChange={cariTipiIslem}
                              options={CariTipiData}
                          />
                        </div>

                        {/* Yetkili Ad Soyad */}
                        <div className="mb-3">
                          <Label htmlFor="yetkiliAdSoyad" className="form-label text-capitalize">
                            Yetkili Ad Soyad
                          </Label>
                          <Input
                              type="text"
                              className="form-control text-capitalize"
                              id="yetkiliAdSoyad"
                              placeholder=" Yetkili Ad Soyad"
                              value={yetkiliAdSoyad}
                              onChange={(e) => setYetkiliAdSoyad(e.target.value)}
                          />
                        </div>

                        {/* Vergi Numarası */}
                        <div className="mb-3">
                          <Label htmlFor="vergiNumarasi" className="form-label text-capitalize">
                            Vergi Numarası
                          </Label>
                          <Input
                              type="number"
                              className="form-control text-capitalize"
                              id="vergiNumarasi"
                              placeholder=" Vergi Numarası"
                              value={vergiNumarasi}
                              onChange={(e) => setVergiNumarasi(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        {/* İş Alanı */}
                        <div className="mb-3">
                          <Label htmlFor="isAlani" className="form-label text-capitalize">
                            İş Alanı
                          </Label>
                          <Input
                              type="text"
                              className="form-control text-capitalize"
                              id="isAlani"
                              placeholder=" İş Alanı"
                              value={isAlani}
                              onChange={(e) => setIsAlani(e.target.value)}
                          />
                        </div>
                        {/* Hesap Kodu */}
                        <div className="mb-3">
                          <Label htmlFor="hesapKodu" className="form-label text-capitalize">
                            Hesap Kodu
                          </Label>
                          <Input
                              type="text"
                              className="form-control text-capitalize"
                              id="hesapKodu"
                              placeholder="Hesap Kodu"
                              value={hesapKodu}
                              onChange={(e) => setHesapKodu(e.target.value)}
                          />
                        </div>
                        {/* Özel Alan */}
                        <div className="mb-3">
                          <Label htmlFor="ozelAlan" className="form-label text-capitalize">
                            Özel Alan
                          </Label>
                          <Input
                              type="text"
                              className="form-control text-capitalize"
                              id="ozelAlan"
                              placeholder="Özel Alan"
                              value={ozelAlan}
                              onChange={(e) => setOzelAlan(e.target.value)}
                          />
                        </div>
                        {/* SMS Tanımı */}
                        <div className="mb-3">
                          <Label htmlFor="smsTanımı" className="form-label text-capitalize">
                            SMS Tanımı
                          </Label>
                          <Select
                              className="text-capitalize"
                              value={smsTanimi}
                              onChange={smsTanimiIslem}
                              options={SmsTanimiData}
                          />
                        </div>
                        {/* Komisyon Grubu */}
                        <div className="mb-3">
                          <Label htmlFor="komisyonGrubu" className="form-label text-capitalize">
                            Komisyon Grubu
                          </Label>
                          <Select
                              className="text-capitalize"
                              value={komisyonGrubu}
                              onChange={komisyonGrubuIslem}
                              options={KomisyonGrubuData}
                          />
                        </div>
                        {/* Durum */}
                        <div className="mb-3">
                          <Label htmlFor="durum" className="form-label text-capitalize">
                            Durum
                          </Label>
                          <Select
                              className="text-capitalize"
                              value={durum}
                              onChange={durumIslem}
                              options={DurumData}
                          />
                        </div>
                      </Col>

                      {/* Listele */}
                      <div className="text-start">
                        <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                          Listele
                        </button>
                      </div>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>

            {/* listeleme kısmı */}
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <div className="mb-3 text-capitalize">
                        {/*<Button color="danger" onClick={handleBulkDelete} disabled={selectedItems.length === 0}>*/}
                        {/*  Toplu Sil*/}
                        {/*</Button>*/}
                      </div>
                      <Table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                        <tr>
                          {/*<th scope="col" style={{ width: "50px" }}>*/}
                          {/*  <div className="form-check">*/}
                          {/*    <Input*/}
                          {/*        className="form-check-input"*/}
                          {/*        type="checkbox"*/}
                          {/*        id="checkAll"*/}
                          {/*        value="option"*/}
                          {/*        onChange={handleSelectAll}*/}
                          {/*        checked={selectedItems.length === displayedCariListesi.length}*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*</th>*/}
                          <th className="text-capitalize">Para Transfer Kodu</th>
                          <th className="text-capitalize">Cari Adı</th>
                          <th className="text-capitalize">Cari Tipi</th>
                          <th className="text-capitalize">Yetkili Ad Soyad</th>
                          <th className="text-capitalize">Vergi Numarası</th>
                          <th className="text-capitalize">İş Alanı</th>
                          <th className="text-capitalize">Hesap Kodu</th>
                          <th className="text-capitalize">Özel Alan</th>
                          <th className="text-capitalize">Sms Tanımı</th>
                          <th className="text-capitalize">Komisyon Grubu</th>
                          <th className="text-capitalize">Durum</th>
                          {/*<th className="text-capitalize">Sil</th>*/}
                        </tr>
                        </thead>
                        {displayedCariListesi.length >= 1 && (
                            <tbody>
                            {displayedCariListesi.map((cari, index) => {
                              const globalIndex = (currentPage - 1) * itemsPerPage + index;
                              const isChecked = selectedItems.includes(globalIndex);
                              return (
                                  <tr key={index} className={isChecked ? "table-active" : ""}>
                                    {/*<td>*/}
                                    {/*  <div className="form-check">*/}
                                    {/*    <Input*/}
                                    {/*        className="form-check-input"*/}
                                    {/*        type="checkbox"*/}
                                    {/*        name="chk_child"*/}
                                    {/*        value="option1"*/}
                                    {/*        checked={isChecked}*/}
                                    {/*        onChange={() => handleSelectItem(globalIndex)}*/}
                                    {/*    />*/}
                                    {/*  </div>*/}
                                    {/*</td>*/}
                                    <td className="text-capitalize">{cari.paraTransferKodu}</td>
                                    <td className="text-capitalize">{cari.cariAdi}</td>
                                    <td className="text-capitalize">{cari.cariTipi}</td>
                                    <td className="text-capitalize">{cari.yetkiliAdSoyad}</td>
                                    <td className="text-uppercase">{cari.vergiNumarasi}</td>
                                    <td className="text-capitalize">{cari.isAlani}</td>
                                    <td className="text-capitalize">{cari.hesapKodu}</td>
                                    <td className="text-capitalize">{cari.ozelAlan}</td>
                                    <th className="text-capitalize">{cari.smsTanimi}</th>
                                    <th>{cari.komisyonGrubu}</th>
                                    <td className="text-capitalize">{cari.durum}</td>
                                    {/*<td className="text-capitalize">*/}
                                    {/*  <Button color="danger" onClick={() => handleDelete(globalIndex)}>Sil</Button>*/}
                                    {/*</td>*/}
                                  </tr>
                              );
                            })}
                            </tbody>
                        )}
                      </Table>

                      {displayedCariListesi.length <= 0 && (
                          <Row>
                            <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                              kayıt bulunamadı
                            </div>
                          </Row>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                      </div>
                      <div>
                        <ul className="pagination mb-0">
                          <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                            <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                          </li>
                          {[...Array(totalPages)].map((_, pageIndex) => (
                              <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                              </li>
                          ))}
                          <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                            <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default CariScreen;
