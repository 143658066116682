import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const HareketTipiData = [
  { value: "hareketTipi1", label: "Hareket Tipi 1" },
  { value: "hareketTipi2", label: "Hareket Tipi 2" },
];
const ParaBirimiData = [
  { value: "TRY", label: "TRY" },
  { value: "USD", label: "USD" },
];
const KategoriData = [
  { value: "kategori1", label: "Kategori 1" },
  { value: "kategori2", label: "Kategori 2" },
];
const AltKategoriData = [
  { value: "altKategori1", label: "Alt Kategori 1" },
  { value: "altKategori2", label: "Alt Kategori 2" },
];


const KategoriRaporu = () => {
  const { t } = useTranslation();
  document.title = t("Kategori Raporu");

  const [firma, setFirma] = useState(null);
  const [hareketTipi, setHareketTipi] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [kategori, setKategori] = useState(null);
  const [altKategori, setAltKategori] = useState(null);
  const [baslangicTarihi1, setBaslangicTarihi1] = useState("");
  const [bitisTarihi1, setBitisTarihi1] = useState("");
  const [baslangicTarihi2, setBaslangicTarihi2] = useState("");
  const [bitisTarihi2, setBitisTarihi2] = useState("");
  const [islemOlanKategoriler, setIslemOlanKategoriler] = useState(true);
  const [altKategorileriGoster, setAltKategorileriGoster] = useState(true);

  const [records, setRecords] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  }
  function hareketTipiIslem(selectedSingle) {
    setHareketTipi(selectedSingle);
  }
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  }
  function kategoriIslem(selectedSingle) {
    setKategori(selectedSingle);
  }
  function altKategoriIslem(selectedSingle) {
    setAltKategori(selectedSingle);
  }
  const baslangicTarihiIslem1 = (e) => {
    setBaslangicTarihi1(e.target.value);
  };
  const bitisTarihiIslem1 = (e) => {
    setBitisTarihi1(e.target.value);
  };
  const baslangicTarihiIslem2 = (e) => {
    setBaslangicTarihi2(e.target.value);
  };
  const bitisTarihiIslem2 = (e) => {
    setBitisTarihi2(e.target.value);
  };

  const formatDateString = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const EkleIslem = async () => {
    if (!firma || !hareketTipi || !paraBirimi || !kategori || !baslangicTarihi1 || !bitisTarihi1 || !baslangicTarihi2 || !bitisTarihi2) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    if (bitisTarihi1 < baslangicTarihi1) {
      durationnotify("bitiş tarihi 1 başlangıç tarihi 1 den küçük olamaz!", 'danger');
      return;
    }
    if (bitisTarihi2 < baslangicTarihi2) {
      durationnotify("bitiş tarihi 2 başlangıç tarihi 2 den küçük olamaz!", 'danger');
      return;
    }
    const yeniKayit = [
      {
        firma: firma.label,
        hareketTipi: hareketTipi.label,
        paraBirimi: paraBirimi.label,
        kategori: kategori.label,
        altKategori: altKategori.label,
        baslangicTarihi1,
        bitisTarihi1,
        baslangicTarihi2,
        bitisTarihi2,
        islemOlanKategoriler,
        altKategorileriGoster,
      }
    ]
    setRecords([...records, ...yeniKayit]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/capraz-virman', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  // geçici buton
  const handleDelete = (index) => {
    const newRecords = records.filter((_, i) => i !== index);
    setRecords(newRecords);
    durationnotify("Kayıt başarıyla silindi.", 'info');
  };
  // const handleDelete = async (index) => {
  //   const itemToDelete = records[index];
  //   try {
  //     const response = await axios.delete(`/api/capraz-virman-delete/${itemToDelete.id}`);
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, i) => i !== index);
  //         setRecords(newRecords);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };


  const totalPages = Math.ceil(records.length / itemsPerPage);
  const handleChangePage = (page) => setCurrentPage(page);

  const displayedRecords = records.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = displayedRecords.map((_, index) => (currentPage - 1) * itemsPerPage + index);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };


  const handleSelectItem = (index) => {
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter((item) => item !== index));
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  // geçici buton
  const handleBulkDelete = () => {
    const newRecords = records.filter((_, index) => !selectedItems.includes(index));
    setRecords(newRecords);
    setSelectedItems([]);
    durationnotify("Seçilen kayıtlar başarıyla silindi.", 'info');
  };
  // const handleBulkDelete = async () => {
  //   const itemsToDelete = selectedItems.map(index => hareketler[index]);
  //   try {
  //     const response = await axios.post('/api/hareketler/bulk-delete', { items: itemsToDelete });
  //     if (response.data.success) {
  //       const newHareketler = hareketler.filter((_, index) => !selectedItems.includes(index));
  //       setHareketler(newHareketler);
  //       setSelectedItems([]);
  //     } else {
  //       console.error('Error: Data was not deleted.');
  //     }
  //   } catch (error) {
  //     console.error('There was an error deleting the data!', error);
  //   }
  // };

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Kategori Raporu")} pageTitle={t("Nakit Yönetimi")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* verileri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Hareket Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="hareketTipi" className="form-label text-capitalize">
                              Hareket Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hareketTipi}
                                onChange={hareketTipiIslem}
                                options={HareketTipiData}
                            />
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>

                          {/* Kategori select */}
                          <div className="mb-3">
                            <Label htmlFor="kategori" className="form-label text-capitalize">
                              Kategori
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={kategori}
                                onChange={kategoriIslem}
                                options={KategoriData}
                            />
                          </div>

                          {/* Alt Kategori select */}
                          <div className="mb-3">
                            <Label htmlFor="altKategori" className="form-label text-capitalize">
                              Alt Kategori
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={altKategori}
                                onChange={altKategoriIslem}
                                options={AltKategoriData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih 1 input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih1" className="form-label text-capitalize">
                              Tarih 1
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi1"
                                    value={baslangicTarihi1}
                                    onChange={baslangicTarihiIslem1}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi1"
                                    value={bitisTarihi1}
                                    onChange={bitisTarihiIslem1}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* Tarih 2 input */}
                          <div className="mb-3">
                            <Label htmlFor="tarih2" className="form-label text-capitalize">
                              Tarih 2
                            </Label>
                            <Row>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="baslangicTarihi2"
                                    value={baslangicTarihi2}
                                    onChange={baslangicTarihiIslem2}
                                />
                              </Col>
                              <Col>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="bitisTarihi2"
                                    value={bitisTarihi2}
                                    onChange={bitisTarihiIslem2}
                                />
                              </Col>
                            </Row>
                          </div>

                          {/* İşlem Olan Kategoriler ve Alt Kategorileri Göster checkbox */}
                          <div className="mt-5 mb-3">
                            <div className="mb-2">
                              <Input type="checkbox" checked={islemOlanKategoriler} id="islem-olan-kategoriler"
                                     className="text-capitalize"
                                     onChange={(e) => setIslemOlanKategoriler(e.target.checked)}/>
                              <Label style={{marginLeft: 5}} for="islem-olan-kategoriler" className="form-label text-uppercase">İşlem Olan Kategoriler</Label>
                            </div>

                            <div>
                              <Input type="checkbox" checked={altKategorileriGoster}
                                     className="pl-2 text-capitalize"
                                     id="alt-kategorileri-goster"
                                     onChange={(e) => setAltKategorileriGoster(e.target.checked)}/>
                              <Label style={{marginLeft: 5}} for="alt-kategorileri-goster" className="form-label text-uppercase">Alt Kategorileri Göster</Label>
                            </div>
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <div className="mb-3 text-capitalize">
                          {/*<Button color="danger" onClick={handleBulkDelete} disabled={selectedItems.length === 0}>*/}
                          {/*  Toplu Sil*/}
                          {/*</Button>*/}
                        </div>
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            {/*<th scope="col" style={{ width: "50px" }}>*/}
                            {/*  <div className="form-check">*/}
                            {/*    <Input*/}
                            {/*        className="form-check-input"*/}
                            {/*        type="checkbox"*/}
                            {/*        id="checkAll"*/}
                            {/*        value="option"*/}
                            {/*        onChange={handleSelectAll}*/}
                            {/*        checked={selectedItems.length === displayedRecords.length}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*</th>*/}
                            <th className="text-capitalize">Firma</th>
                            <th className="text-capitalize">Hareket Tipi</th>
                            <th className="text-capitalize">Para Birimi</th>
                            <th className="text-capitalize">Kategori</th>
                          </tr>
                          </thead>

                          {displayedRecords.length >= 1 && (
                              <tbody>
                              {displayedRecords.map((records, index) => {
                                const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                const isChecked = selectedItems.includes(globalIndex);
                                return (
                                    <tr key={index} className={isChecked ? "table-active" : ""}>
                                      {/*<td>*/}
                                      {/*  <div className="form-check">*/}
                                      {/*    <Input*/}
                                      {/*        className="form-check-input"*/}
                                      {/*        type="checkbox"*/}
                                      {/*        name="chk_child"*/}
                                      {/*        value="option1"*/}
                                      {/*        checked={isChecked}*/}
                                      {/*        onChange={() => handleSelectItem(globalIndex)}*/}
                                      {/*    />*/}
                                      {/*  </div>*/}
                                      {/*</td>*/}
                                      <td className="text-uppercase">{records.firma}</td>
                                      <td className="text-uppercase">{records.hareketTipi}</td>
                                      <td className="text-uppercase">{records.paraBirimi}</td>
                                      <td className="text-capitalize">{records.kategori}</td>

                                      {/*<td className="text-capitalize">*/}
                                      {/*  <Button color="danger" onClick={() => handleDelete(globalIndex)}>Sil</Button>*/}
                                      {/*</td>*/}
                                    </tr>
                                );
                              })}
                              </tbody>
                          )}
                        </Table>

                        {displayedRecords.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div>
                        </div>
                        <div>
                          <ul className="pagination mb-0">
                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>Önceki</Link>
                            </li>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                  <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                              <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>Sonraki</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default KategoriRaporu;
