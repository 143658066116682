import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Table
} from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HaftalikRaporChart from "./Chart/HaftalikRaporChart";
import {useTranslation} from "react-i18next";

const FirmaData = [
  { value: "firma1", label: "Firma 1" },
  { value: "firma2", label: "Firma 2" },
];
const HareketTipiData = [
  { value: "hareketTipi1", label: "Hareket Tipi 1" },
  { value: "hareketTipi1", label: "Hareket Tipi 2" },
];
const ParaBirimiData = [
  { value: "tl", label: "TL" },
  { value: "dolar", label: "DOLAR" },
  { value: "eur", label: "EURO" },
];

const TarihData = [
  { value: "ocak", label: "Ocak" },
  { value: "subat", label: "Şubat" },
  { value: "mart", label: "Mart" },
  { value: "nisan", label: "Nisan" },
  { value: "mayis", label: "Mayıs" },
  { value: "haziran", label: "Haziran" },
  { value: "temmuz", label: "Temmuz" },
  { value: "agustos", label: "Ağustos" },
  { value: "eylul", label: "Eylül" },
  { value: "ekim", label: "Ekim" },
  { value: "kasim", label: "Kasım" },
  { value: "aralik", label: "Aralık" },
];


const HaftalikRapor = () => {
  const { t } = useTranslation();
  document.title = t("Haftalık Rapor");

  const [firma, setFirma] = useState(null);
  const [hareketTipi, setHareketTipi] = useState(null);
  const [tarih, setTarih] = useState(null);
  const [paraBirimi, setParaBirimi] = useState(null);
  const [records, setRecords] = useState([]);

  const durationnotify = (message, type = 'success') => toast(message, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
    autoClose: 5000
  });

  function firmaIslem(selectedSingle) {
    setFirma(selectedSingle);
  };
  function hareketTipiIslem(selectedSingle) {
    setHareketTipi(selectedSingle);
  };
  const tarihIslem = (selectedSingle) => {
    setTarih(selectedSingle);
  };
  function paraBirimiIslem(selectedSingle) {
    setParaBirimi(selectedSingle);
  };

  const EkleIslem = async () => {
    if (!firma || !hareketTipi || !tarih || !paraBirimi) {
      durationnotify("Tüm alanları doldurmanız gerekmektedir!", 'danger');
      return;
    }
    const yeniKayit = {
      firma: firma.label,
      hareketTipi: hareketTipi.label,
      tarih: tarih.label,
      paraBirimi: paraBirimi.label,
    };

    let apiVerisi = [
      { tarih: "Pazartesi", islemAdedi: "745", toplamTutar: 85193871.82 },
      { tarih: "Salı", islemAdedi: "264", toplamTutar: 92225308.63 },
      { tarih: "Çarşamba", islemAdedi: "66", toplamTutar: 7577814.35 },
      { tarih: "Perşembe", islemAdedi: "403", toplamTutar: 16584488.10 },
      { tarih: "Cuma", islemAdedi: "129", toplamTutar: 64540947.32 },
      { tarih: "Cumartesi", islemAdedi: "0", toplamTutar: 0.00 },
      { tarih: "Pazar", islemAdedi: "0", toplamTutar: 0.00 }
    ];

    setRecords([...records, apiVerisi]);
    durationnotify("Kayıt başarıyla eklendi!", 'success');

    // aşağıdaki kısım api hazır olunca kullanılacak kısım
    // try {
    //   const response = await axios.post('/api/gun-sonu-bakiye', yeniKayit);
    //   if (response.data.success) {
    //       setRecords([...records, yeniKayit]);
    //   } else {
    //     console.error('Error: Data was not added.');
    //   }
    // } catch (error) {
    //   console.error('There was an error adding the data!', error);
    // }
  };

  const calculateTotal = (records, field, isCurrency = false) => {
    const total = records.reduce((acc, record) => {
      const value = isCurrency ? parseFloat(record[field] || 0) : parseInt(record[field] || 0, 10);
      return acc + value;
    }, 0);
    return isCurrency ? total.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : total.toLocaleString('tr-TR');
  };
  const formatCurrency = (value) => {
    return value.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };


  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Haftalık Rapor")} pageTitle={t("Zaman Raporları")} pageLink="online-banka" />
            <Col xs={12}>
              <ToastContainer />

              {/* veri ekleme */}
              <Row>
                <Col lg={12}>
                  <Card>
                    <div style={{
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 25,
                      marginRight: 25,
                    }}>

                      {/*inputlar, selectler ve listele buton*/}
                      <Row>
                        {/*inputlar ve selectler*/}
                        <Col lg={6}>
                          {/* Firma select */}
                          <div className="mb-3">
                            <Label htmlFor="firma" className="form-label text-capitalize">
                              Firma
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={firma}
                                onChange={firmaIslem}
                                options={FirmaData}
                            />
                          </div>

                          {/* Hareket Tipi select */}
                          <div className="mb-3">
                            <Label htmlFor="hareketTipi" className="form-label text-capitalize">
                              Hareket Tipi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={hareketTipi}
                                onChange={hareketTipiIslem}
                                options={HareketTipiData}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          {/* Tarih select */}
                          <div className="mb-3">
                            <Label htmlFor="tarih" className="form-label text-capitalize">
                              Tarih
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={tarih}
                                onChange={tarihIslem}
                                options={TarihData}
                            />
                          </div>

                          {/* Para Birimi select */}
                          <div className="mb-3">
                            <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                              Para Birimi
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={paraBirimi}
                                onChange={paraBirimiIslem}
                                options={ParaBirimiData}
                            />
                          </div>
                        </Col>

                        {/* listele buton */}
                        <div className="text-start">
                          <button type="submit" className="btn btn-primary text-capitalize" onClick={() => EkleIslem()}>
                            Listele
                          </button>
                        </div>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              {/* chart */}
              <Row>
                <Col lg={12}>
                  <Card style={{height: records.length > 0 ? 400 : 0, width: "100%"}}>
                    <CardBody>
                      <HaftalikRaporChart dataColors='["--vz-success"]' apiVerisi={records.length > 0 ? records[records.length - 1] : []}/>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* verileri tablo olarak listeleme*/}
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                          <tr>
                            <th className="text-capitalize">Günler</th>
                            <th className="text-capitalize">İşlem Adedi</th>
                            <th className="text-capitalize">Toplam Tutar</th>
                          </tr>
                          </thead>

                          {records.length >= 1 && (
                              <tbody>
                              {records[0].map((records, index) => {
                                return (
                                    <tr key={index}>
                                      <td className="text-capitalize">{records.tarih}</td>
                                      <td>{records.islemAdedi}</td>
                                      <td>{formatCurrency(records.toplamTutar)}</td>
                                    </tr>
                                );
                              })}

                              {/* Toplam Satırı */}
                              <tr className="table-light">
                                <td className="text-uppercase" style={{ fontWeight: "bold" }}>TOPLAM</td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records[0], 'islemAdedi')}
                                </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {calculateTotal(records[0], 'toplamTutar', true)}
                                </td>
                              </tr>
                              </tbody>
                          )}
                        </Table>

                        {records.length <= 0 && (
                            <Row>
                              <div style={{width: "100%", height: 45}} className="d-flex justify-content-center align-items-center border-2 border-bottom text-capitalize">
                                kayıt bulunamadı
                              </div>
                            </Row>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
      </React.Fragment>
  );
};

export default HaftalikRapor;
