import React from 'react';
import {Card, Col, Container, Form, Input, Label, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SifreDegistir = () => {
    const { t } = useTranslation();
    document.title = t("Şifre Değiştir");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Şifre Değiştir")} pageTitle={t("Ayarlar")} pageLink=""/>
                    <Col xs={12}>
                        <Card className="p-4">
                            <Form>
                                <Row className="g-2">
                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="oldpasswordInput" className="form-label text-capitalize">
                                                Eski Şifre*
                                            </Label>
                                            <Input type="password" className="form-control text-capitalize"
                                                   id="oldpasswordInput"
                                                   placeholder="Eski Şifre"/>
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="newpasswordInput" className="form-label text-capitalize">
                                                Yeni Şifre*
                                            </Label>
                                            <Input type="password" className="form-control text-capitalize"
                                                   id="newpasswordInput" placeholder="Yeni Şifre"/>
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div>
                                            <Label htmlFor="confirmpasswordInput" className="form-label text-capitalize">
                                                Yeni Şifreyi Onayla*
                                            </Label>
                                            <Input type="password" className="form-control text-capitalize"
                                                   id="confirmpasswordInput"
                                                   placeholder="Yeni Şifreyi Onayla"/>
                                        </div>
                                    </Col>

                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Link to="/forgot-password" className="link-primary text-decoration-underline text-capitalize">
                                                Şifreni mi unuttun?
                                            </Link>
                                        </div>
                                    </Col>

                                    <Col lg={12}>
                                        <div className="text-start">
                                            <button type="button" className="btn btn-success text-capitalize">
                                                Şifreni Değiştir
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SifreDegistir;
